import { useState } from "react";
import { ReactComponent as Type1 } from "../../assets/images/type1.svg";
import { ReactComponent as Type2 } from "../../assets/images/type2.svg";

export const Title = ({
  title,
  type,
  direction,
  setDirection,
}: {
  title: string;
  type: boolean;
  direction?: boolean;
  setDirection?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [lang, setLang] = useState("ua");
  const [searching, setSearching] = useState(false);

  const onLanguageButtonClick = () => {
    if (lang === "ua") {
      setLang("eng");
    } else {
      setLang("ua");
    }
  };

  const onSearchInputChange = () => {};

  const onSearchInputFocus = () => {
    setSearching(true);
  };

  const onSearchInputBlur = () => {
    setSearching(false);
  };
  return (
    <div
      className={
        searching
          ? type
            ? "title title__searching title__typing"
            : "title title__searching"
          : type
          ? "title title__typing"
          : "title"
      }
    >
      <h1
        className={
          !searching
            ? title === "DO YOU WANNA LO-FI?"
              ? "title__text title__text--appearing "
              : "title__text"
            : "title__text--hide"
        }
      >
        {title}
      </h1>
      {type && (
        <div className="title__types--wrapper">
          <div
            className="title__type"
            style={
              direction
                ? {
                    backgroundColor: "#251F1E80",
                  }
                : { backgroundColor: "transparent" }
            }
            onClick={() => {
              if (setDirection) {
                setDirection((prev) => !prev);
              }
            }}
          >
            <Type1 />
          </div>
          <div
            className="title__type"
            onClick={() => {
              if (setDirection) {
                setDirection((prev) => !prev);
              }
            }}
            style={
              direction
                ? { backgroundColor: "transparent" }
                : {
                    backgroundColor: "#251F1E80",
                  }
            }
          >
            <Type2 />
          </div>
        </div>
      )}
      <div
        className={searching ? "title__wrapper width100" : "title__wrapper out"}
      >
        {!searching && (
          <div onClick={onLanguageButtonClick} className="title__language">
            {lang}
          </div>
        )}
        <input
          type="text"
          placeholder="пошук"
          className={
            searching ? "title__input __input--active width100" : "title__input"
          }
          onChange={onSearchInputChange}
          onFocus={onSearchInputFocus}
          onBlur={onSearchInputBlur}
        />
      </div>
    </div>
  );
};
