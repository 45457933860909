import { useState } from "react";
import { ReactComponent as Conditions } from "../../assets/images/conditions.svg";
import { ReactComponent as Payment } from "../../assets/images/payment.svg";
import { Title } from "../../components/Title/Title";

export const Delivery = () => {
  const [direction, setDirection] = useState(true);

  return (
    <section className="delivery">
      <div className="container__two">
        <Title
          title="Доставка"
          type={false}
          direction={direction}
          setDirection={setDirection}
        />
        <div className="delivery__thumb">
          <div className="delivery__item">
            <div className="delivery__wrapper">
              <Conditions />
              <h2 className="delivery__title">Умови</h2>
            </div>
            <p className="delivery__text--wrapper">
              <p className="delivery__text">
                У нашому каталозі Ви можете знайти два типи товарів - товари зі
                статусом «в наявності» і товари зі статусом «передзамовлення».
                Товари «в наявності» знаходяться у нас на складі і їх доставка
                здійснюється протягом 1-2 днів.
              </p>
              <p className="delivery__text">
                Товари зі статусом «передзамовлення» замовляються офіційно з
                лейблів. Зазвичай, доставка таких товарів займає від 4 до 6
                тижнів (іноді буває більше), тому вам необхідно запастися
                терпінням і трохи почекати. Товари зі статусом «передзамовлення»
                оплачуються за фактом отримання на склад, з передолатою у
                розмірі 10% від вартості.
              </p>
              <p className="delivery__text">
                Просимо Вас уважно перевіряти контактні дані при оформленні
                замовлення, тому що ми не несемо відповідальності за доставку
                товару, якщо Ваша адреса вказана невірно!
              </p>
            </p>
          </div>
          <div className="delivery__item">
            <div className="delivery__wrapper">
              <Payment />
              <h2 className="delivery__title">Оплата</h2>
            </div>
            <div className="delivery__text--wrapper">
              <p className="delivery__text">
                Оплатити Ваше замовлення Ви можете за допомогою карт Visa або
                Mastercard. Оформивши замовлення на сайті, менеджер зв'яжеться з
                Вами протягом дня для уточнення замовлення та надання реквізитів
                для оплати.
              </p>
              <p className="delivery__text">
                Також є можливість оплати товарів післяплатою кур'єру або у
                відділенні Нової Пошти.
              </p>
              <p className="delivery__text">
                При оформленні "передзамовлення" менеджер обов'язково зв'яжеться
                з Вами і уточнить всю інформацію з приводу доставки та оплати
                замовлення
              </p>
              <p className="delivery__text">
                Ми продаємо лише нові вінілові платівки, тому не можемо
                перевірити їх стан і якість. У разі заводського дефекту магазин
                відповідальності не несе.
              </p>
            </div>
          </div>
        </div>
        <div className="delivery__info">
          <h3 className="delivery__info--title">Примітки:</h3>
          <p className="delivery__info--text">
            Доставка по Україні здійснюється транспортною компанією "Нова Пошта"
            згідно з тарифами і умовами компанії, з якими ви можете ознайомитись
            на сайті: https://novaposhta.ua
          </p>
          <p className="delivery__info--text">
            Вінілові платівки, касети та мерч, обміну та поверненню не
            підлягають.
          </p>
        </div>
      </div>
    </section>
  );
};
