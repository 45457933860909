import { useEffect, useState } from "react";
import { Title } from "../../components/Title/Title";
import cassette from "../../assets/images/cassette1.png";
import { Sort } from "../../components/Sort/Sort";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { Cassette } from "../../interfaces/cassette";
import { getAllCassettes } from "../../services/axios";

export const CassetteComp = () => {
  const [direction, setDirection] = useState(true);
  const [inStock, setInStock] = useState(true);
  const [data, setData] = useState<Cassette[] | []>([]);
  const [language, setLanguage] = useState("uk");

  useEffect(() => {
    const fetchAllCassettes = async () => {
      const data = await getAllCassettes(language);
      setData(data);
    };

    fetchAllCassettes();
  }, [language]);

  return (
    <div className="cassette">
      <div className="container__two">
        <Title
          title="Касети"
          type={true}
          direction={direction}
          setDirection={setDirection}
        />
        <Sort />
        <ProductsList data={data} direction={direction} />
      </div>
    </div>
  );
};
