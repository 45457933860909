import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";
import { ReactComponent as Instagram } from "../../assets/images/instagram.svg";
import { ReactComponent as Tiktok } from "../../assets/images/tiktok.svg";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__thumb">
        <div className="footer__thumb--left">
          <p className="footer__title">DO YOU WANNA LO-FI?</p>
          <div className="footer__info--wrapper footer__location">
            <p className="footer__text footer__location--text">
              Всі права збережені
            </p>
            <p
              className="footer__text footer__text--location footer__location--text"
              style={{ paddingLeft: 0, border: 0 }}
            >
              м. Дніпро
            </p>
          </div>
        </div>
        <div className="footer__thumb--right">
          <div className="footer__wrapper">
            <p className="footer__title">Магазин</p>
            <div className="footer__info--wrapper">
              <NavLink to="vinyl" className="footer__link">
                <p className="footer__text">Вініл</p>
              </NavLink>
              <NavLink to="cassette" className="footer__link">
                <p className="footer__text">Касети</p>
              </NavLink>
              <NavLink to="merch" className="footer__link">
                <p className="footer__text">Мерч</p>
              </NavLink>
            </div>
          </div>
          <div className="footer__wrapper">
            <p className="footer__title">Доставка</p>
            <div className="footer__info--wrapper">
              <NavLink to="delivery" className="footer__link">
                <p className="footer__text">Умови</p>
              </NavLink>
              <NavLink to="delivery" className="footer__link">
                <p className="footer__text">Оплата</p>
              </NavLink>
            </div>
          </div>
          <div className="footer__wrapper">
            <p className="footer__title">Контакти</p>
            <div className="footer__info--wrapper footer__social--wrapper">
              <a href="https://t.me/douwannalofi">
                <p className="footer__text">
                  <Telegram className="footer__svg" />
                  <span className="footer__social">Telegram</span>
                </p>
              </a>
              <a href="https://www.instagram.com/doyouwannalofi/">
                <p className="footer__text">
                  <Instagram className="footer__svg" />
                  <span className="footer__social">Instagram</span>
                </p>
              </a>
              <a href="https://www.tiktok.com/@sonic_w4ve">
                <p className="footer__text">
                  <Tiktok className="footer__svg" />
                  <span className="footer__social">Tiktok</span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
