import { NavLink } from "react-router-dom";
import { ReactComponent as Label } from "../../assets/images/label.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as ActiveLogo } from "../../assets/images/logoActive.svg";
import { ReactComponent as Vinyl } from "../../assets/images/vinyl.svg";
import { ReactComponent as Cassette } from "../../assets/images/cassette.svg";
import { ReactComponent as Merch } from "../../assets/images/merch.svg";
// import { ReactComponent as Blog } from "../../assets/images/blog.svg";
import { ReactComponent as Cart } from "../../assets/images/cart.svg";
import { ReactComponent as Burger } from "../../assets/images/burger.svg";
import { ReactComponent as CloseBurger } from "../../assets/images/closeBurger.svg";
import { ReactComponent as CartMobile } from "../../assets/images/cart-mobile.svg";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { useState } from "react";

export const Header = () => {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [language, setLanguage] = useState("ua");
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <>
      <header className="header">
        <NavLink to="/" className="header__link">
          <Logo className="header__icon--logo" />
          <ActiveLogo className="header__icon--activeLogo" />
        </NavLink>
        <NavLink to="label" className="header__link header__link--label">
          <Label className="header__icon--label" />
        </NavLink>
        <NavLink to="vinyl" className="header__link header__link--vinyl">
          <Vinyl className="header__icon--vinyl" />
        </NavLink>
        <NavLink to="cassette" className="header__link header__link--cassette">
          <Cassette className="header__icon--cassette" />
        </NavLink>
        <NavLink to="merch" className="header__link header__link--merch">
          <Merch className="header__icon--merch" />
        </NavLink>
        {/* <NavLink to="blog" className="header__link header__link--blog">
          <Blog className="header__icon--blog" />
        </NavLink> */}
        <NavLink to="cart" className="header__link header__link--cart">
          <Cart className="header__icon--cart" />
        </NavLink>
      </header>
      <header className="header__mobile">
        <div className="header__mobile--thumb">
          {isBurgerMenuOpen ? (
            <CloseBurger
              style={{ width: isSearchOpen ? "36px" : "" }}
              onClick={() => {
                setIsBurgerMenuOpen(false);
                setIsSearchOpen(false);
              }}
            />
          ) : (
            <Burger
              onClick={() => {
                setIsBurgerMenuOpen(true);
              }}
            />
          )}
          {!isBurgerMenuOpen && <p className="header__mobile--title">DYWLF?</p>}
          <div
            className="header__mobile--wrapper"
            style={{ width: isSearchOpen && isBurgerMenuOpen ? "100%" : "" }}
          >
            {isBurgerMenuOpen && (
              <div
                className="header__search--wrapper"
                style={{
                  width: isSearchOpen && isBurgerMenuOpen ? "85%" : "",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Search
                  className={
                    isSearchOpen && isBurgerMenuOpen
                      ? "header__search--icon"
                      : ""
                  }
                  onClick={() => {
                    setIsSearchOpen(true);
                  }}
                />
                {isSearchOpen && isBurgerMenuOpen && (
                  <input type="text" className="header__mobile--input" />
                )}
              </div>
            )}
            <CartMobile />
          </div>
        </div>
        <div
          className={
            isBurgerMenuOpen
              ? "header__mobile--modal"
              : "header__mobile--modal header__modal--hidden"
          }
        >
          <div
            className="header__modal--item"
            onClick={() => {
              window.location.href = "/label";
            }}
          >
            <Label className="header__icon--label" />
            <p className="header__modal--title">Лейбл</p>
          </div>
          <div
            className="header__modal--item"
            onClick={() => {
              window.location.href = "/vinyl";
            }}
          >
            <Vinyl className="header__icon--vinyl" />
            <p className="header__modal--title">Вініл</p>
          </div>
          <div
            className="header__modal--item"
            onClick={() => {
              window.location.href = "/cassette";
            }}
          >
            <Cassette className="header__icon--cassette" />
            <p className="header__modal--title">Касети</p>
          </div>
          <div
            className="header__modal--item"
            onClick={() => {
              window.location.href = "/merch";
            }}
          >
            <Merch className="header__icon--merch" />
            <p className="header__modal--title">Мерч</p>
          </div>
          <div className="header__modal--item">
            <div className="header__language--wrapper">
              <button
                type="button"
                onClick={() => {
                  setLanguage("ua");
                }}
                className={
                  language === "ua"
                    ? "header__modal--langButton header__langButton--active"
                    : "header__modal--langButton"
                }
              >
                UA
              </button>
              <button
                type="button"
                onClick={() => {
                  setLanguage("eng");
                }}
                className={
                  language === "eng"
                    ? "header__modal--langButton header__langButton--active"
                    : "header__modal--langButton"
                }
              >
                ENG
              </button>
            </div>
            <p className="header__modal--title header__modal--titleBlack">
              Мова
            </p>
          </div>
        </div>
      </header>
      <div className={isBurgerMenuOpen ? "header__mobile--backdrop" : ""}></div>
    </>
  );
};
