import { Link } from "react-router-dom";
import { Cassette } from "../../interfaces/cassette";
import { Merch } from "../../interfaces/merch";
import { Vinyl } from "../../interfaces/vinyl";

export const ProductsList = ({
  data,
  direction,
}: {
  data: Vinyl[] | Cassette[] | [];
  direction: boolean;
}) => {
  return (
    <ul
      className={
        direction
          ? "productsList__list"
          : "productsList__list productsList__list--type2"
      }
    >
      {data?.map(
        ({ _id, coverPicture, artistName, albumName, uah, uahSale }, index) => (
          <li
            key={index}
            className={
              direction
                ? "productsList__item productsList__item--type1"
                : "productsList__item productsList__item--type2"
            }
          >
            <img
              src={coverPicture}
              alt={artistName}
              className="productsList__image"
            />
            <div className="productsList__thumb">
              <p
                className="productsList__author"
                style={
                  direction ? { textAlign: "center" } : { textAlign: "start" }
                }
              >
                {artistName}
              </p>
              <p
                className="productsList__name"
                style={
                  direction ? { textAlign: "center" } : { textAlign: "start" }
                }
              >
                {albumName}
              </p>
              <div
                className="productsList__price--wrapper"
                style={
                  direction
                    ? { justifyContent: "center" }
                    : { justifyContent: "flex-start" }
                }
              >
                {uahSale !== 0 && (
                  <p
                    className="productsList__price--sale"
                    style={
                      direction
                        ? { textAlign: "center" }
                        : { textAlign: "start" }
                    }
                  >
                    <s>1650 грн.</s>
                  </p>
                )}
                <p
                  className="productsList__price"
                  style={
                    direction ? { textAlign: "center" } : { textAlign: "start" }
                  }
                >
                  {uah}
                </p>
              </div>
              <Link to={`/release/${_id}`}>
                <button type="button" className="productsList__button">
                  Придбати
                </button>
              </Link>
            </div>
          </li>
        )
      )}
    </ul>
  );
};
