import { useLottie } from "lottie-react";
import loader from "../../assets/loader/pre-loader-1.json";

export const Loader = () => {
  const options = {
    animationData: loader,
    loop: true,
  };

  console.log("sa");

  const { View } = useLottie(options);

  return <div>{View}</div>;
};
