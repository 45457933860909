import axios from "axios";

export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);

  try {
    const response = await axios.post(
      "https://dywlf.com/api/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data.imageUrl;
  } catch (error) {
    console.error("Error uploading the file:", error);
  }
};

export const createArtist = async (data) => {
  await axios.post("https://dywlf.com/api/artist/create", data);
  return;
};

export const createVinyl = async (data) => {
  await axios.post("https://dywlf.com/api/vinyl/create", data);
  return;
};

export const createCassette = async (data) => {
  await axios.post("https://dywlf.com/api/cassette/create", data);
  return;
};

export const createMerch = async (data) => {
  await axios.post("https://dywlf.com/api/merch/create", data);
  return;
};

export const updateArtist = async (data) => {
  await axios.patch("https://dywlf.com/api/artist/update", data);
};

export const updateVinyl = async (data) => {
  await axios.patch("https://dywlf.com/api/vinyl/update", data);
};

export const updateCassette = async (data) => {
  await axios.patch("https://dywlf.com/api/cassette/update", data);
};

export const updateMerch = async (data) => {
  await axios.patch("https://dywlf.com/api/merch/update", data);
};

export const archiveArtist = async (_id, archived) => {
  await axios.patch("https://dywlf.com/api/artist/archive", {
    _id,
    archived,
  });
};

export const archiveVinyl = async (_id, archived) => {
  await axios.patch("https://dywlf.com/api/vinyl/archive", {
    _id,
    archived,
  });
};

export const archiveCassette = async (_id, archived) => {
  await axios.patch("https://dywlf.com/api/cassette/archive", {
    _id,
    archived,
  });
};

export const archiveMerch = async (_id, archived) => {
  await axios.patch("https://dywlf.com/api/merch/archive", {
    _id,
    archived,
  });
};

export const deleteArtist = async (_id) => {
  await axios.delete(`https://dywlf.com/api/artist/delete/${_id}`);
};

export const deleteVinyl = async (_id) => {
  await axios.delete(`https://dywlf.com/api/vinyl/delete/${_id}`);
};

export const deleteCassette = async (_id) => {
  await axios.delete(`https://dywlf.com/api/cassette/delete/${_id}`);
};

export const deleteMerch = async (_id) => {
  await axios.delete(`https://dywlf.com/api/merch/delete/${_id}`);
};

export const getAllArtists = async (language) => {
  const { data } = await axios.get(
    `https://dywlf.com/api/artist/getAll?language=${language}`
  );
  return data;
};

export const getAllVinyls = async (language) => {
  const { data } = await axios.get(
    `https://dywlf.com/api/vinyl/getAll?language=${language}`
  );
  return data;
};

export const getAllCassettes = async (language) => {
  const { data } = await axios.get(
    `https://dywlf.com/api/cassette/getAll?language=${language}`
  );
  return data;
};

export const getAllMerchs = async (language) => {
  const { data } = await axios.get(
    `https://dywlf.com/api/merch/getAll?language=${language}`
  );
  return data;
};

export const getAllShowcases = async (language) => {
  const { data } = await axios.get(
    `https://dywlf.com/api/showcase/getAll?language=${language}`
  );
  return data;
};

export const getArtist = async (_id, language) => {
  const { data } = await axios.get(
    `https://dywlf.com/api/artist/get/${_id}?language=${language}`
  );
  return data;
};

export const getVinyl = async (_id, language) => {
  const { data } = await axios.get(
    `https://dywlf.com/api/vinyl/get/${_id}?language=${language}`
  );
  return data;
};

export const getCassette = async (_id, language) => {
  const { data } = await axios.get(
    `https://dywlf.com/api/cassette/get/${_id}?language=${language}`
  );
  return data;
};

export const getMerch = async (_id, language) => {
  const { data } = await axios.get(
    `https://dywlf.com/api/merch/get/${_id}?language=${language}`
  );
  return data;
};

export const getUserCart = async (ids, types, colors, sizes, language) => {
  try {
    const userCart = {
      ids,
      types,
      colors,
      sizes,
      language,
    };
    const { data } = await axios.post(
      "https://dywlf.com/api/cart/userCart",
      userCart
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUsdExchange = async () => {
  try {
    const { data } = await axios.get("https://dywlf.com/api/exchangeRate/get");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteShowcase = async (_id) => {
  const { data } = await axios.delete(
    `https://dywlf.com/api/showcase/delete/${_id}`
  );
  return data;
};

export const updateShowcase = async (info) => {
  const { data } = await axios.patch(
    `https://dywlf.com/api/showcase/update`,
    info
  );
  return data;
};

export const deleteImage = async (fileName) => {
  await axios.delete(`https://dywlf.com/api/delete/${fileName}`);
};
