import { Swiper, SwiperSlide } from "swiper/react";
import { Title } from "../../components/Title/Title";
import "swiper/css";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import { useEffect, useState } from "react";
import { getMerch } from "../../services/axios";
import { useParams } from "react-router-dom";
import { Merch } from "../../interfaces/merch";

SwiperCore.use([Navigation, Pagination]);
export const ReleaseMerch = () => {
  const [direction, setDirection] = useState(true);
  const [data, setData] = useState<Merch | null>(null);
  const [language, setLanguage] = useState("uk");
  const [sizesOpen, setSizesOpen] = useState(false);
  const [size, setSize] = useState("XL");
  const [colorOpen, setColorOpen] = useState(false);
  const [currentColor, setCurrentColor] = useState("#fff");
  const [currentIndex, setCurrentIndex] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    const fetchItem = async () => {
      const itemMerch = await getMerch(id, language);
      setData(itemMerch);
    };

    fetchItem();
  }, [language, id]);

  useEffect(() => {
    if (data) {
      const currentSize = data?.info[currentIndex].xl
        ? "XL"
        : data?.info[currentIndex].l
        ? "L"
        : data?.info[currentIndex].m
        ? "M"
        : data?.info[currentIndex].s
        ? "S"
        : "XS";
      setSize(currentSize);
      setCurrentColor(data.info[currentIndex].color);
    }
  }, [data, currentIndex]);

  const onButtonBuyClick = () => {
    const userCartIds = localStorage.getItem("userCartIds");
    if (userCartIds !== null) {
      const ids = JSON.parse(userCartIds);
      ids.push(id);
      localStorage.setItem("userCartIds", JSON.stringify(ids));
    } else {
      localStorage.setItem("userCartIds", JSON.stringify([id]));
    }
    const userCartTypes = localStorage.getItem("userCartTypes");
    if (userCartTypes !== null) {
      const types = JSON.parse(userCartTypes);
      types.push(data?.type);
      localStorage.setItem("userCartTypes", JSON.stringify(types));
    } else {
      localStorage.setItem("userCartTypes", JSON.stringify([data?.type]));
    }
    const userCartColors = localStorage.getItem("userCartColors");
    if (userCartColors !== null) {
      const colors = JSON.parse(userCartColors);
      colors.push(currentColor);
      localStorage.setItem("userCartColors", JSON.stringify(colors));
    } else {
      localStorage.setItem("userCartColors", JSON.stringify([currentColor]));
    }
    const userCartSizes = localStorage.getItem("userCartSizes");
    if (userCartSizes !== null) {
      const sizes = JSON.parse(userCartSizes);
      sizes.push(size);
      localStorage.setItem("userCartSizes", JSON.stringify(sizes));
    } else {
      localStorage.setItem("userCartSizes", JSON.stringify([size]));
    }
    const userCartQuantities = localStorage.getItem("userCartQuantities");
    if (userCartQuantities !== null) {
      const quantities = JSON.parse(userCartQuantities);
      quantities.push(1);
      localStorage.setItem("userCartQuantities", JSON.stringify(quantities));
    } else {
      localStorage.setItem("userCartQuantities", JSON.stringify([1]));
    }
    setTimeout(() => {
      window.location.href = "/cart";
    }, 500);
  };
  return (
    <section className="release">
      <div className="container__two">
        <Title
          title="Реліз"
          type={false}
          direction={direction}
          setDirection={setDirection}
        />
        <div className="release__container">
          {data && (
            <img
              src={data?.info[currentIndex].image}
              alt={data?.name}
              className="release__image"
            />
          )}
          <div className="release__wrapper">
            <h2 className="release__title">
              {data?.name} - {data?.from}
            </h2>
            <p
              className="release__availability"
              style={{
                color:
                  data?.availability === "inStock"
                    ? "#35AFA3"
                    : data?.availability === "preOrder"
                    ? "#F5CD22"
                    : "#FB4839",
              }}
            >
              {data?.availability === "inStock"
                ? "В наявності"
                : data?.availability === "preOrder"
                ? "Передзамовлення"
                : "Немає в наявності"}
            </p>
            <div className="release__sizes--wrapper">
              <p className="release__sizesColors">Розмір:</p>
              <div className="release__size--active">{size}</div>
              <div className="release__sizes--thumb">
                {size !== "XL" && data?.info[currentIndex].xl ? (
                  <p
                    className="release__size"
                    onClick={() => {
                      setSize("XL");
                    }}
                  >
                    XL
                  </p>
                ) : (
                  ""
                )}
                {size !== "L" && data?.info[currentIndex].l ? (
                  <p
                    className="release__size"
                    onClick={() => {
                      setSize("L");
                    }}
                  >
                    L
                  </p>
                ) : (
                  ""
                )}
                {size !== "M" && data?.info[currentIndex].m ? (
                  <p
                    className="release__size"
                    onClick={() => {
                      setSize("M");
                    }}
                  >
                    M
                  </p>
                ) : (
                  ""
                )}
                {size !== "S" && data?.info[currentIndex].s ? (
                  <p
                    className="release__size"
                    onClick={() => {
                      setSize("S");
                    }}
                  >
                    S
                  </p>
                ) : (
                  ""
                )}
                {size !== "XS" && data?.info[currentIndex].xs ? (
                  <p
                    className="release__size"
                    onClick={() => {
                      setSize("XS");
                    }}
                  >
                    XS
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="release__sizes--wrapper">
              <p
                className="release__sizesColors"
                style={{ marginLeft: "12px" }}
              >
                Колір:
              </p>
              <div className="release__color--active">
                <div
                  className="release__color--box"
                  style={{ background: currentColor }}
                ></div>
              </div>
              <div
                className="release__sizes--thumb"
                style={{ paddingLeft: "10px" }}
              >
                {data?.info.map(({ color }, index) =>
                  color !== currentColor ? (
                    <div
                      key={color}
                      onClick={() => {
                        setCurrentColor(color);
                        setCurrentIndex(index);
                      }}
                      className="release__color--box"
                      style={{
                        background: color,
                        cursor: "pointer",
                      }}
                    ></div>
                  ) : (
                    <div key={color} style={{ display: "none" }}></div>
                  )
                )}
              </div>
            </div>
            {/* <p className="release__subtitle">
              Лейбл: <span className="release__span">{data?.labelName}</span>
            </p>
            <p className="release__subtitle">
              Видання:
              <span className="release__span"> {data?.issue}</span>
            </p>
            <p className="release__subtitle">
              Виробник: <span className="release__span">{data?.country}</span>
            </p> */}
            <p className="release__subtitle">
              Опис:
              <span className="release__span" style={{ color: "#C8BEBC" }}>
                {" "}
                {data?.description}
              </span>
            </p>
            <p className="release__price">
              {data?.uah} грн.
              {data?.uahSale !== 0 && (
                <span className="release__price--sale">
                  <s>{data?.uahSale} грн.</s>
                </span>
              )}
            </p>
            <button
              type="button"
              className="release__button"
              onClick={() => {
                onButtonBuyClick();
              }}
            >
              Придбати
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
