import { Title } from "../../components/Title/Title";
import { ReactComponent as Minus } from "../../assets/images/cartMinus.svg";
import { ReactComponent as Plus } from "../../assets/images/cartPlus.svg";
import { ReactComponent as Cross } from "../../assets/images/cartCross.svg";
import album from "../../assets/images/album1.png";
import { useEffect, useState } from "react";
import { getUsdExchange, getUserCart } from "../../services/axios";
import { Merch } from "../../interfaces/merch";
import { Cassette } from "../../interfaces/cassette";
import { Vinyl } from "../../interfaces/vinyl";
import { Sizes, SizesLowerCase } from "../../interfaces/sizes";

export const Cart = () => {
  const [userCart, setUserCart] = useState([]);
  const [userCartIds, setUserCartIds] = useState([]);
  const [userCartTypes, setUserCartTypes] = useState([]);
  const [userCartColors, setUserCartColors] = useState([]);
  const [userCartSizes, setUserCartSizes] = useState<Sizes[] | []>([]);
  const [quantities, setQuantities] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(41);
  const [language, setLangugage] = useState("uk");

  useEffect(() => {
    const fetchUserCart = async () => {
      setUserCart(
        await getUserCart(
          userCartIds,
          userCartTypes,
          userCartColors,
          userCartSizes,
          language
        )
      );
      setExchangeRate(await getUsdExchange());
    };

    fetchUserCart();
  }, [userCartIds, userCartTypes, userCartColors, userCartSizes, language]);

  useEffect(() => {
    setQuantities(
      JSON.parse(localStorage.getItem("userCartQuantities") ?? "[]")
    );
    setUserCartIds(JSON.parse(localStorage.getItem("userCartIds") || "[]"));
    setUserCartTypes(JSON.parse(localStorage.getItem("userCartTypes") || "[]"));
    setUserCartColors(
      JSON.parse(localStorage.getItem("userCartColors") || "[]")
    );
    setUserCartSizes(JSON.parse(localStorage.getItem("userCartSizes") || "[]"));
  }, []);

  function findIndexByColor(
    info: [
      {
        xl: number;
        l: number;
        m: number;
        s: number;
        xs: number;
        color: string;
        image: string;
        oneSizeAmount: number;
      }
    ],
    color: string
  ) {
    return info.findIndex((item) => item.color === color);
  }

  const updateQuantity = (
    index: number,
    value: number,
    info: [
      {
        xl: number;
        l: number;
        m: number;
        s: number;
        xs: number;
        color: string;
        image: string;
        oneSizeAmount: number;
      }
    ]
  ) => {
    const quantityExist = localStorage.getItem("userCartQuantities");
    let quantities = quantityExist ? JSON.parse(quantityExist) : [];

    if (!Array.isArray(quantities)) {
      quantities = [];
    }

    quantities[index] = (quantities[index] || 1) + value;

    if (info) {
      const size = userCartSizes[index].toLocaleLowerCase() as SizesLowerCase;
      if (
        quantities[index] >
        info[findIndexByColor(info, userCartColors[index])][size]
      ) {
        quantities[index] =
          info[findIndexByColor(info, userCartColors[index])][size];
      }
    }
    if (quantities[index] < 1) quantities[index] = 1;

    localStorage.setItem("userCartQuantities", JSON.stringify(quantities));
    setQuantities(quantities);
  };

  const countTotal = () => {
    let total = 0;
    let index = 0;
    for (const { usd } of userCart) {
      total += usd * exchangeRate * quantities[index];
      index += 1;
    }
    return total;
  };

  const onItemDeleteButtonClick = (index: number) => {
    setUserCartIds((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartIds", JSON.stringify(item));
      return item;
    });
    setUserCartTypes((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartTypes", JSON.stringify(item));
      return item;
    });
    setUserCartColors((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartColors", JSON.stringify(item));
      return item;
    });
    setUserCartSizes((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartSizes", JSON.stringify(item));
      return item;
    });
    setQuantities((prev) => {
      const item = [...prev];
      item.splice(index, 1);
      localStorage.setItem("userCartQuantities", JSON.stringify(item));
      return item;
    });
  };

  return (
    <div className="cart">
      <div className="container__two">
        <Title title="Кошик" type={false} />
        <div className="cart__wrapper">
          <div className="cart__bill">
            <p className="cart__bill--title">Чек</p>
            <div className="cart__bill--line"></div>
            <p className="cart__bill--total">
              Усього:{" "}
              <span className="cart__bill--sum">
                {Math.round(countTotal())} грн
              </span>
            </p>
            <button type="button" className="cart__bill--button">
              Сплатити
            </button>
          </div>
          {userCart.map(
            (
              {
                _id,
                type,
                coverPicture,
                albumName,
                artistName,
                name,
                from,
                usd,
                info,
              }: {
                _id: string;
                type: string;
                coverPicture: string;
                albumName: string;
                artistName: string;
                name: string;
                from: string;
                usd: number;
                info: [
                  {
                    xl: number;
                    l: number;
                    m: number;
                    s: number;
                    xs: number;
                    color: string;
                    image: string;
                    oneSizeAmount: number;
                  }
                ];
              },
              index
            ) => (
              <div className="cart__item" key={_id}>
                <div className="cart__top--wrapper">
                  <div className="cart__quantity--wrapper">
                    <Minus
                      onClick={() => updateQuantity(index, -1, info)}
                      className="cart__cursor--pointer"
                    />
                    <div className="cart__top--quantity">
                      {quantities[index]}
                    </div>
                    <Plus
                      onClick={() => updateQuantity(index, 1, info)}
                      className="cart__cursor--pointer"
                    />
                  </div>
                  <Cross
                    className="cart__cursor--pointer"
                    onClick={() => {
                      onItemDeleteButtonClick(index);
                    }}
                  />
                </div>
                <div className="cart__item--wrapper">
                  <img
                    src={
                      type === "merch"
                        ? info[findIndexByColor(info, userCartColors[index])]
                            ?.image
                          ? info[findIndexByColor(info, userCartColors[index])]
                              ?.image
                          : ""
                        : coverPicture
                    }
                    alt={type === "merch" ? name : albumName}
                    className={
                      type === "merch"
                        ? "cart__item--merch"
                        : "cart__item--image"
                    }
                  />
                  <div className="cart__item--thumb">
                    {type === "merch" ? (
                      <div className="cart__merch--wrapper">
                        <p className="cart__merch--sizeColor">
                          Розмір:{" "}
                          <span className="cart__merch--sizeActive">
                            {userCartSizes[index]}
                          </span>
                        </p>
                        <div className="cart__merch--thumb">
                          <p className="cart__merch--sizeColor">Колір:</p>
                          <div className="cart__item--box"></div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <p className="cart__item--artistName">
                      {type === "merch" ? name : artistName}
                    </p>
                    <p className="cart__item--albumName">
                      {type === "merch" ? from : albumName}
                    </p>
                    <p className="cart__item--price">
                      {Math.round(usd * exchangeRate)} грн.
                    </p>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
