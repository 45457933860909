import { useState } from "react";
import { Tabs } from "./Tabs";
import { Vinyl } from "../../interfaces/vinyl";
import { Cassette } from "../../interfaces/cassette";
import { Merch } from "../../interfaces/merch";
import { ActionsShop } from "./ActionsShop";
import { VinylList } from "./VinylList";
import { CassetteList } from "./CassetteList";
import { MerchList } from "./MerchList";
import { EditGoods } from "./EditGoods";
import { ShowcaseList } from "./ShowcaseList";

export const AdminGoods = () => {
  const [language, setLanguage] = useState("uk");
  const [type, setType] = useState("vinyl");
  const [vinyl, setVinyl] = useState<Vinyl | null>(null);
  const [cassette, setCassette] = useState<Cassette | null>(null);
  const [merch, setMerch] = useState<Merch | null>(null);

  return vinyl || cassette || merch ? (
    <>
      <EditGoods
        types={type}
        setVinyl={setVinyl}
        vinyl={vinyl}
        setCassette={setCassette}
        cassette={cassette}
        setMerch={setMerch}
        merch={merch}
        language={language}
      />
    </>
  ) : (
    <>
      <Tabs />
      <ActionsShop
        language={language}
        setLanguage={setLanguage}
        type={type}
        setType={setType}
      />
      {type === "vinyl" ? (
        <VinylList language={language} setVinyl={setVinyl} />
      ) : type === "cassette" ? (
        <CassetteList language={language} setCassette={setCassette} />
      ) : type === "merch" ? (
        <MerchList language={language} setMerch={setMerch} />
      ) : (
        type === "showcase" && <ShowcaseList language={language} />
      )}
    </>
  );
};
