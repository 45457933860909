import { Link } from "react-router-dom";
import { ReactComponent as AdminBack } from "../../assets/images/adminBack.svg";
import { useState } from "react";
import { ReactComponent as OtherImg } from "../../assets/images/otherImg.svg";
import { ReactComponent as AddArtistAlbum } from "../../assets/images/addArtistAlbum.svg";
import { createArtist, deleteImage, uploadImage } from "../../services/axios";
import { ReactComponent as AdminBin } from "../../assets/images/adminBin.svg";
import { getLastImagePart } from "../../utils/getLastImagePart";

export const AddArtist = () => {
  const [artistName, setArtistName] = useState("");
  const [telegramLink, setTelegramLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [albumName, setAlbumName] = useState([""]);
  const [albumImage, setAlbumImage] = useState([""]);
  const [spotifyLink, setSpotifyLink] = useState([""]);
  const [appleMusicLink, setAppleMusicLink] = useState([""]);
  const [deezerLink, setDeezerLink] = useState([""]);
  const [bandcampLink, setBandcampLink] = useState([""]);
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [albums, setAlbums] = useState([1]);
  const [artistNameError, setArtistNameError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const onDeleteImageClick = async (url: string) => {
    deleteImage(getLastImagePart(url));
  };

  const onSubmitButtonClick = async () => {
    if (artistName && image && description) {
      createArtist({
        artistName,
        image,
        telegramLink,
        instagramLink,
        description,
        albumName,
        albumImage,
        spotifyLink,
        appleMusicLink,
        deezerLink,
        bandcampLink,
        links: [link1, link2],
        archived: false,
        language: "uk",
        adapted: true,
      });

      createArtist({
        artistName,
        image,
        telegramLink,
        instagramLink,
        description,
        albumName,
        albumImage,
        spotifyLink,
        appleMusicLink,
        deezerLink,
        bandcampLink,
        links: [link1, link2],
        archived: false,
        language: "en",
        adapted: false,
      });

      setTimeout(() => {
        window.location.href = "/admin/label";
      }, 500);
    } else {
      if (!artistName) {
        setArtistNameError(true);
      } else {
        setArtistNameError(false);
      }

      if (!description) {
        setDescriptionError(true);
      } else {
        setDescriptionError(false);
      }

      if (!image) {
        setImageError(true);
      } else {
        setImageError(false);
      }
    }
  };

  const onAddAlbumButtonClick = async () => {
    setAlbumName((prev) => [...prev, ""]);
    setAlbumImage((prev) => [...prev, ""]);
    setSpotifyLink((prev) => [...prev, ""]);
    setAppleMusicLink((prev) => [...prev, ""]);
    setDeezerLink((prev) => [...prev, ""]);
    setBandcampLink((prev) => [...prev, ""]);
    setAlbums((prev) => [...prev, 1]);
  };

  const onDeleteAlbumButtonClick = async (index: number) => {
    setAlbumName((prev) => prev.filter((_, i) => i !== index));
    setAlbumImage((prev) => prev.filter((_, i) => i !== index));
    setSpotifyLink((prev) => prev.filter((_, i) => i !== index));
    setAppleMusicLink((prev) => prev.filter((_, i) => i !== index));
    setDeezerLink((prev) => prev.filter((_, i) => i !== index));
    setBandcampLink((prev) => prev.filter((_, i) => i !== index));
    setAlbums((prev) => prev.filter((_, i) => i !== index));
  };
  return (
    <div className="admin__addGoods">
      <div className="admin__addGoods--wrapper">
        <Link to="/admin/label" className="admin__back--link">
          <AdminBack />
        </Link>
        <div className="admin__addArtist--title">Add artist</div>
      </div>
      <p className="admin__addGoods--label">Name of Artist</p>
      <div className="admin__addGoods--inputWrapper">
        <input
          type="text"
          className={
            artistNameError
              ? "admin__addGoods--input admin__addGoods--inputError"
              : "admin__addGoods--input"
          }
          value={artistName}
          onChange={(e) => {
            setArtistName(e.target.value);
            setArtistNameError(false);
          }}
          placeholder="Name"
        />
      </div>
      <p className="admin__addGoods--label">Image</p>
      <div className="admin__addArtist--artistfileWrapper">
        <div
          className={
            imageError
              ? "admin__addArtist--fileThumb dashedBorderRed"
              : "admin__addArtist--fileThumb dashedBorder"
          }
        >
          <input
            type="file"
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                const url = await uploadImage(e.target.files[0]);
                setImage(url);
                setImageError(false);
              }
            }}
            className="admin__addGoods--inputFile"
            style={{ width: "100%", height: "100%" }}
          />
          {image && (
            <AdminBin
              className="admin__image--bin"
              onClick={() => {
                onDeleteImageClick(image);
                setImage("");
              }}
            />
          )}
          <OtherImg className="admin__addGoods--coverImg" />
          {image && (
            <img
              src={image}
              alt="artist"
              className="admin__addArtist--artistImg"
            />
          )}
        </div>
      </div>
      <p className="admin__addArtist--info">
        drop or click (min size 600x600 px; max images 4)
      </p>
      <p className="admin__addGoods--label">Social links</p>
      <div className="admin__addGoods--inputWrapper">
        <input
          type="text"
          className="admin__addGoods--input"
          value={telegramLink}
          onChange={(e) => {
            setTelegramLink(e.target.value);
          }}
          placeholder="telegram link"
        />
        <input
          type="text"
          className="admin__addGoods--input"
          value={instagramLink}
          onChange={(e) => {
            setInstagramLink(e.target.value);
          }}
          placeholder="instagram link"
        />
      </div>
      <p className="admin__addGoods--label">Description</p>
      <div className="admin__addGoods--inputWrapper">
        <textarea
          className={
            descriptionError
              ? "admin__addGoods--input admin__addGoods--inputError"
              : "admin__addGoods--input"
          }
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            setDescriptionError(false);
          }}
          placeholder="Description (max 800 symbols)"
        />
      </div>
      <p className="admin__addGoods--label">Releases</p>
      {albums.map((_, index) => {
        return (
          <div className="admin__addArtist--inputWrapper" key={index}>
            <div className="admin__addArtist--inputAlbumWrapper">
              <input
                type="text"
                className="admin__addGoods--input admin__addArtist--input"
                value={albumName[index]}
                onChange={(e) => {
                  setAlbumName((prev) => {
                    const arr = [...prev];
                    arr[index] = e.target.value;
                    return arr;
                  });
                }}
                placeholder="Album name"
              />
              <AdminBin
                className="admin__addArtist--bin"
                onClick={() => {
                  onDeleteAlbumButtonClick(index);
                }}
              />
            </div>
            <div>
              <div
                className="admin__addArtist--fileWrapper"
                style={{ gap: "0" }}
              >
                <div
                  className="admin__addGoods--fileThumb dashedBorder"
                  style={{ width: "200px" }}
                >
                  {albumImage[index] && (
                    <img
                      src={albumImage[index]}
                      alt={albumName[index]}
                      className="admin__addArtist--artistImage"
                    />
                  )}
                  <input
                    type="file"
                    onChange={async (
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      if (e.target.files) {
                        const url = await uploadImage(e.target.files[0]);
                        setAlbumImage((prev) => {
                          const arr = [...prev];
                          arr[index] = url;
                          return arr;
                        });
                      }
                    }}
                    className="admin__addArtist--inputFile"
                  />
                  {albumImage[index] && (
                    <AdminBin
                      className="admin__image--bin"
                      onClick={() => {
                        onDeleteImageClick(albumImage[index]);
                        setAlbumImage((prev) =>
                          prev.filter((_, i) => i !== index)
                        );
                      }}
                    />
                  )}
                  {!albumImage[index] && (
                    <div className="admin__addArtist--coverImgWrapper">
                      <div className="admin__addArtist--coverImgThumb">
                        <p className="admin__addArtist--coverImgText">Photo</p>
                        <OtherImg className="admin__addArtist--coverImg" />
                      </div>
                      <div className="admin__addArtist--wrapperDescription">
                        <p className="admin__addArtist--coverImgDescription">
                          drop or click
                        </p>
                        <p className="admin__addArtist--coverImgDescription">
                          (min size 200х200)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="admin__addGoods--inputWrapper"
                  style={{
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "0",
                    width: "73.7%",
                  }}
                >
                  <input
                    type="text"
                    className="admin__addGoods--input"
                    value={spotifyLink[index]}
                    style={{ height: "50px" }}
                    onChange={(e) => {
                      setSpotifyLink((prev) => {
                        const arr = [...prev];
                        arr[index] = e.target.value;
                        return arr;
                      });
                    }}
                    placeholder="Spotify link"
                  />
                  <input
                    type="text"
                    className="admin__addGoods--input"
                    value={appleMusicLink[index]}
                    style={{ height: "50px" }}
                    onChange={(e) => {
                      setAppleMusicLink((prev) => {
                        const arr = [...prev];
                        arr[index] = e.target.value;
                        return arr;
                      });
                    }}
                    placeholder="Apple Music link"
                  />
                  <input
                    type="text"
                    className="admin__addGoods--input"
                    value={deezerLink[index]}
                    style={{ height: "50px" }}
                    onChange={(e) => {
                      setDeezerLink((prev) => {
                        const arr = [...prev];
                        arr[index] = e.target.value;
                        return arr;
                      });
                    }}
                    placeholder="Deezer link"
                  />
                  <input
                    type="text"
                    className="admin__addGoods--input"
                    value={bandcampLink[index]}
                    style={{ height: "50px" }}
                    onChange={(e) => {
                      setBandcampLink((prev) => {
                        const arr = [...prev];
                        arr[index] = e.target.value;
                        return arr;
                      });
                    }}
                    placeholder="Bandcamp link"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <button
        type="button"
        className="admin__addArtist--addAlbum"
        onClick={() => {
          onAddAlbumButtonClick();
        }}
      >
        Add album/single/EP <AddArtistAlbum />
      </button>
      <p className="admin__addGoods--label">You might like it</p>
      <div className="admin__addGoods--inputWrapper">
        <input
          type="text"
          className="admin__addGoods--input"
          value={link1}
          onChange={(e) => {
            setLink1(e.target.value);
          }}
          placeholder="goods link #1"
        />
        <input
          type="text"
          className="admin__addGoods--input"
          value={link2}
          onChange={(e) => {
            setLink2(e.target.value);
          }}
          placeholder="goods link #2"
        />
      </div>
      <button
        type="button"
        className="admin__addArtist--submit"
        onClick={() => {
          onSubmitButtonClick();
        }}
      >
        Зберегти зміни
      </button>
    </div>
  );
};
