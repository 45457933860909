import { Link } from "react-router-dom";
import { ReactComponent as AdminBack } from "../../assets/images/adminBack.svg";
import { useState } from "react";
import { ReactComponent as CoverImg } from "../../assets/images/coverImg.svg";
import { ReactComponent as VinylCoverImg } from "../../assets/images/vinylCoverImg.svg";
import { ReactComponent as CassetteCoverImg } from "../../assets/images/cassetteCoverImg.svg";
import { ReactComponent as OtherImg } from "../../assets/images/otherImg.svg";
import { ReactComponent as AddColor } from "../../assets/images/addGoods.svg";
import {
  deleteImage,
  getAllShowcases,
  updateCassette,
  updateMerch,
  updateVinyl,
  uploadImage,
} from "../../services/axios";
import { Vinyl } from "../../interfaces/vinyl";
import { Cassette } from "../../interfaces/cassette";
import { Merch } from "../../interfaces/merch";
import { getLastImagePart } from "../../utils/getLastImagePart";
import { ReactComponent as AdminBin } from "../../assets/images/adminBin.svg";

export const EditGoods = ({
  types,
  setVinyl,
  vinyl,
  setCassette,
  cassette,
  setMerch,
  merch,
  language,
}: {
  types: string;
  vinyl: Vinyl | null;
  setVinyl: React.Dispatch<React.SetStateAction<Vinyl | null>>;
  setCassette: React.Dispatch<React.SetStateAction<Cassette | null>>;
  cassette: Cassette | null;
  setMerch: React.Dispatch<React.SetStateAction<Merch | null>>;
  merch: Merch | null;
  language: string;
}) => {
  const [type, setType] = useState(types);
  const [availability, setAvailability] = useState(
    types === "vinyl"
      ? vinyl?.availability
      : types === "cassette"
      ? cassette?.availability
      : type === "merch"
      ? merch?.availability
      : "inStock"
  );
  const [amount, setAmount] = useState(
    types === "vinyl"
      ? vinyl?.amount
      : types === "cassette"
      ? cassette?.amount
      : type === "merch"
      ? merch?.amount
      : 0
  );
  const [priority, setPriority] = useState(
    types === "vinyl"
      ? vinyl?.priority
      : types === "cassette"
      ? cassette?.priority
      : 0
  );
  const [showcase, setShowcase] = useState(
    types === "vinyl"
      ? vinyl?.showcase
      : types === "cassette"
      ? cassette?.showcase
      : false
  );
  const [showcaseImage, setShowcaseImage] = useState(
    types === "vinyl"
      ? vinyl?.showcaseImage
      : types === "cassette"
      ? cassette?.showcaseImage
      : ""
  );
  const [artistName, setArtistName] = useState(
    types === "vinyl"
      ? vinyl?.artistName
      : types === "cassette"
      ? cassette?.artistName
      : ""
  );
  const [albumName, setAlbumName] = useState(
    types === "vinyl"
      ? vinyl?.albumName
      : types === "cassette"
      ? cassette?.albumName
      : ""
  );
  const [name, setName] = useState(types === "merch" ? merch?.name : "");
  const [from, setFrom] = useState(types === "merch" ? merch?.from : "");
  const [labelName, setLabelName] = useState(
    types === "vinyl"
      ? vinyl?.labelName
      : types === "cassette"
      ? cassette?.labelName
      : ""
  );
  const [issue, setIssue] = useState(
    types === "vinyl"
      ? vinyl?.issue
      : types === "cassette"
      ? cassette?.issue
      : ""
  );
  const [country, setCountry] = useState(
    types === "vinyl"
      ? vinyl?.country
      : types === "cassette"
      ? cassette?.country
      : ""
  );
  const [genre, setGenre] = useState(
    types === "vinyl"
      ? vinyl?.genre
      : types === "cassette"
      ? cassette?.genre
      : ""
  );
  const [uah, setUah] = useState(
    types === "vinyl"
      ? vinyl?.uah
      : types === "cassette"
      ? cassette?.uah
      : type === "merch"
      ? merch?.uah
      : 0
  );
  const [usd, setUsd] = useState(
    types === "vinyl"
      ? vinyl?.usd
      : types === "cassette"
      ? cassette?.usd
      : type === "merch"
      ? merch?.usd
      : 0
  );
  const [uahSale, setUahSale] = useState(
    types === "vinyl"
      ? vinyl?.uahSale
      : types === "cassette"
      ? cassette?.uahSale
      : type === "merch"
      ? merch?.uahSale
      : 0
  );
  const [usdSale, setUsdSale] = useState(
    types === "vinyl"
      ? vinyl?.usdSale
      : types === "cassette"
      ? cassette?.usdSale
      : type === "merch"
      ? merch?.usdSale
      : 0
  );
  const [spotifyCode, setSpotifyCode] = useState(
    types === "vinyl"
      ? vinyl?.tracklist
      : types === "cassette"
      ? cassette?.tracklist
      : ""
  );
  const [tracklist, setTracklist] = useState(
    types === "vinyl"
      ? vinyl?.tracklist
      : types === "cassette"
      ? cassette?.tracklist
      : ""
  );
  const [link1, setLink1] = useState(
    types === "vinyl"
      ? vinyl?.links
        ? vinyl?.links[0]
          ? vinyl?.links[0]
          : ""
        : ""
      : types === "cassette"
      ? cassette?.links
        ? cassette?.links[0]
          ? cassette?.links[0]
          : ""
        : ""
      : type === "merch"
      ? merch?.links
        ? merch?.links[0]
          ? merch?.links[0]
          : ""
        : ""
      : ""
  );

  const [link2, setLink2] = useState(
    types === "vinyl"
      ? vinyl?.links
        ? vinyl?.links[1]
          ? vinyl?.links[1]
          : ""
        : ""
      : types === "cassette"
      ? cassette?.links
        ? cassette?.links[1]
          ? cassette?.links[1]
          : ""
        : ""
      : type === "merch"
      ? merch?.links
        ? merch?.links[1]
          ? merch?.links[1]
          : ""
        : ""
      : ""
  );
  const [link3, setLink3] = useState(
    types === "vinyl"
      ? vinyl?.links
        ? vinyl?.links[2]
          ? vinyl?.links[2]
          : ""
        : ""
      : types === "cassette"
      ? cassette?.links
        ? cassette?.links[2]
          ? cassette?.links[2]
          : ""
        : ""
      : type === "merch"
      ? merch?.links
        ? merch?.links[2]
          ? merch?.links[2]
          : ""
        : ""
      : ""
  );
  const [tags, setTags] = useState(
    types === "vinyl"
      ? vinyl?.tags
      : types === "cassette"
      ? cassette?.tags
      : type === "merch"
      ? merch?.tags
      : ""
  );
  const [sale, setSale] = useState(
    types === "vinyl"
      ? vinyl?.usdSale !== 0
        ? true
        : false
      : types === "cassette"
      ? cassette?.usdSale !== 0
        ? true
        : false
      : type === "merch"
      ? merch?.usdSale !== 0
        ? true
        : false
      : false
  );
  const [info, setInfo] = useState(
    types === "merch"
      ? merch?.info
        ? merch?.info
        : [
            {
              xl: 0,
              l: 0,
              m: 0,
              s: 0,
              xs: 0,
              color: "",
              image: "",
              oneSizeAmount: 0,
            },
          ]
      : [
          {
            xl: 0,
            l: 0,
            m: 0,
            s: 0,
            xs: 0,
            color: "",
            image: "",
            oneSizeAmount: 0,
          },
        ]
  );
  const [infos, setInfos] = useState(
    types === "merch"
      ? merch?.info
        ? new Array(merch?.info.length).fill(1)
        : [1]
      : [1]
  );
  const [oneSize, setOneSize] = useState(
    types === "merch"
      ? merch?.info
        ? merch?.info[0].oneSizeAmount !== 0
          ? true
          : false
        : false
      : false
  );
  const [oneColor, setOneColor] = useState(
    types === "merch"
      ? merch?.info
        ? merch?.info[0].oneSizeAmount !== 0
          ? true
          : false
        : false
      : false
  );
  const [description, setDescription] = useState(
    types === "merch" ? merch?.description : ""
  );
  const [coverPicture, setCoverPicture] = useState(
    types === "vinyl"
      ? vinyl?.coverPicture
      : types === "cassette"
      ? cassette?.coverPicture
      : ""
  );
  const [otherPicture1, setOtherPicture1] = useState(
    types === "vinyl"
      ? vinyl?.otherPictures
        ? vinyl?.otherPictures[0]
          ? vinyl?.otherPictures[0]
          : ""
        : ""
      : types === "cassette"
      ? cassette?.otherPictures
        ? cassette?.otherPictures[0]
          ? cassette?.otherPictures[0]
          : ""
        : ""
      : ""
  );
  const [otherPicture2, setOtherPicture2] = useState(
    types === "vinyl"
      ? vinyl?.otherPictures
        ? vinyl?.otherPictures[1]
          ? vinyl?.otherPictures[1]
          : ""
        : ""
      : types === "cassette"
      ? cassette?.otherPictures
        ? cassette?.otherPictures[1]
          ? cassette?.otherPictures[1]
          : ""
        : ""
      : ""
  );
  const [otherPicture3, setOtherPicture3] = useState(
    types === "vinyl"
      ? vinyl?.otherPictures
        ? vinyl?.otherPictures[2]
          ? vinyl?.otherPictures[2]
          : ""
        : ""
      : types === "cassette"
      ? cassette?.otherPictures
        ? cassette?.otherPictures[2]
          ? cassette?.otherPictures[2]
          : ""
        : ""
      : ""
  );
  const [otherPicture4, setOtherPicture4] = useState(
    types === "vinyl"
      ? vinyl?.otherPictures
        ? vinyl?.otherPictures[3]
          ? vinyl?.otherPictures[3]
          : ""
        : ""
      : types === "cassette"
      ? cassette?.otherPictures
        ? cassette?.otherPictures[3]
          ? cassette?.otherPictures[3]
          : ""
        : ""
      : ""
  );
  const [oneImage, setOneImage] = useState(
    types === "merch" ? (merch?.oneImage ? merch?.oneImage : "") : ""
  );
  const [artistNameError, setArtistNameError] = useState(false);
  const [albumNameError, setAlbumNameError] = useState(false);
  const [coverPictureError, setCoverPictureError] = useState(false);
  const [labelNameError, setLabelNameError] = useState(false);
  const [issueError, setIssueError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [genreError, setGenreError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [fromError, setFromError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const onDeleteImageClick = async (url: string) => {
    deleteImage(getLastImagePart(url));
  };

  const onSubmitButtonClick = async () => {
    let amountMerch = 0;

    for (const size of info) {
      amountMerch += size.xl;
      amountMerch += size.l;
      amountMerch += size.m;
      amountMerch += size.s;
      amountMerch += size.xs;
      amountMerch += size.oneSizeAmount;
    }

    const otherPictures: string[] = [];
    [otherPicture1, otherPicture2, otherPicture3, otherPicture4].forEach(
      (picture) => {
        if (picture !== "") {
          otherPictures.push(picture);
        }
      }
    );

    const links: string[] = [];
    [link1, link2, link3].forEach((link) => {
      if (link !== "") {
        links.push(link);
      }
    });

    const data = await getAllShowcases("uk");

    if (type === "vinyl") {
      console.log("vin");
      if (
        artistName &&
        albumName &&
        coverPicture &&
        labelName &&
        issue &&
        country &&
        genre &&
        tracklist
      ) {
        if (language === "uk") {
          updateVinyl({
            _id: vinyl?._id,
            data: {
              availability,
              amount,
              priority,
              showcaseImage,
              showcase,
              artistName,
              albumName,
              coverPicture,
              otherPictures,
              labelName,
              issue,
              country,
              genre,
              uah,
              usd,
              uahSale,
              usdSale,
              order: vinyl?.showcase
                ? vinyl?.order
                : showcase
                ? data
                  ? data.length + 1
                  : 1
                : 0,
              tracklist: tracklist ? tracklist : spotifyCode,
              links,
              tags,
              archived: false,
              language: "uk",
              adapted: true,
            },
          });
          setTimeout(() => {
            window.location.href = "/admin/shop";
          }, 500);
        } else {
          updateVinyl({
            _id: vinyl?._id,
            data: {
              availability,
              amount,
              priority,
              showcase,
              showcaseImage,
              artistName,
              albumName,
              coverPicture,
              otherPictures,
              labelName,
              issue,
              country,
              genre,
              uah,
              usd,
              uahSale,
              usdSale,
              order: vinyl?.showcase
                ? vinyl?.order
                : showcase
                ? data
                  ? data.length + 1
                  : 1
                : 0,
              tracklist: tracklist ? tracklist : spotifyCode,
              links,
              tags,
              archived: false,
              language: "en",
              adapted: true,
            },
          });
          setTimeout(() => {
            window.location.href = "/admin/shop";
          }, 500);
        }
      } else {
        if (!artistName) {
          setArtistNameError(true);
        } else {
          setArtistNameError(false);
        }

        if (!albumName) {
          setAlbumNameError(true);
        } else {
          setAlbumNameError(false);
        }

        if (!coverPicture) {
          setCoverPictureError(true);
        } else {
          setCoverPictureError(false);
        }

        if (!labelName) {
          setLabelNameError(true);
        } else {
          setLabelNameError(false);
        }

        if (!issue) {
          setIssueError(true);
        } else {
          setIssueError(false);
        }

        if (!country) {
          setCountryError(true);
        } else {
          setCountryError(false);
        }

        if (!genre) {
          setGenreError(true);
        } else {
          setGenreError(false);
        }
      }
    } else if (type === "cassette") {
      console.log("cas");
      if (
        artistName &&
        albumName &&
        coverPicture &&
        labelName &&
        issue &&
        country &&
        genre &&
        tracklist
      ) {
        if (language === "uk") {
          updateCassette({
            _id: cassette?._id,
            data: {
              availability,
              amount,
              priority,
              showcase,
              showcaseImage,
              artistName,
              albumName,
              coverPicture,
              otherPictures,
              labelName,
              issue,
              country,
              genre,
              uah,
              usd,
              uahSale,
              usdSale,
              order: vinyl?.showcase
                ? vinyl?.order
                : showcase
                ? data
                  ? data.length + 1
                  : 1
                : 0,
              tracklist: tracklist ? tracklist : spotifyCode,
              links,
              tags,
              archived: false,
              language: "uk",
              adapted: true,
            },
          });
          setTimeout(() => {
            window.location.href = "/admin/shop";
          }, 500);
        } else {
          updateCassette({
            _id: cassette?._id,
            data: {
              availability,
              amount,
              priority,
              showcase,
              showcaseImage,
              artistName,
              albumName,
              coverPicture,
              otherPictures,
              labelName,
              issue,
              country,
              genre,
              uah,
              usd,
              uahSale,
              usdSale,
              order: vinyl?.showcase
                ? vinyl?.order
                : showcase
                ? data
                  ? data.length + 1
                  : 1
                : 0,
              tracklist: tracklist ? tracklist : spotifyCode,
              links,
              tags,
              archived: false,
              language: "en",
              adapted: true,
            },
          });
          setTimeout(() => {
            window.location.href = "/admin/shop";
          }, 500);
        }
      } else {
        if (!artistName) {
          setArtistNameError(true);
        } else {
          setArtistNameError(false);
        }

        if (!albumName) {
          setAlbumNameError(true);
        } else {
          setAlbumNameError(false);
        }

        if (!coverPicture) {
          setCoverPictureError(true);
        } else {
          setCoverPictureError(false);
        }

        if (!labelName) {
          setLabelNameError(true);
        } else {
          setLabelNameError(false);
        }

        if (!issue) {
          setIssueError(true);
        } else {
          setIssueError(false);
        }

        if (!country) {
          setCountryError(true);
        } else {
          setCountryError(false);
        }

        if (!genre) {
          setGenreError(true);
        } else {
          setGenreError(false);
        }
      }
    } else if (type === "merch") {
      console.log("sadadasda");
      if (name && from && description) {
        console.log("here2");
        if (language === "uk") {
          console.log("here3");
          updateMerch({
            _id: merch?._id,
            data: {
              name,
              from,
              availability,
              amount: amountMerch,
              info,
              oneColor,
              oneImage,
              description,
              uah,
              usd,
              uahSale,
              usdSale,
              links,
              tags,
              order: vinyl?.showcase
                ? vinyl?.order
                : showcase
                ? data
                  ? data.length + 1
                  : 1
                : 0,
              archived: false,
              language: "uk",
              adapted: true,
            },
          });
          setTimeout(() => {
            window.location.href = "/admin/shop";
          }, 500);
        } else {
          updateMerch({
            _id: merch?._id,
            data: {
              name,
              from,
              availability,
              amount: amountMerch,
              info,
              oneColor,
              oneImage,
              description,
              uah,
              usd,
              uahSale,
              usdSale,
              links,
              tags,
              order: vinyl?.showcase
                ? vinyl?.order
                : showcase
                ? data
                  ? data.length + 1
                  : 1
                : 0,
              archived: false,
              language: "en",
              adapted: true,
            },
          });
          setTimeout(() => {
            window.location.href = "/admin/shop";
          }, 500);
        }
      } else {
        if (!name) {
          setNameError(true);
        } else {
          setNameError(false);
        }

        if (!from) {
          setFromError(true);
        } else {
          setFromError(false);
        }

        if (!description) {
          setDescriptionError(true);
        } else {
          setDescriptionError(false);
        }
      }
    }
  };

  const onAddColorButtonClick = async () => {
    setInfo((prev) => [
      ...prev,
      {
        xl: 0,
        l: 0,
        m: 0,
        s: 0,
        xs: 0,
        color: "",
        image: "",
        oneSizeAmount: 0,
      },
    ]);

    setInfos((prev) => [...prev, 1]);
  };

  const onDeleteColorButtonClick = async (index: number) => {
    setInfo((prev) => prev.filter((_, i) => i !== index));
    setInfos((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="admin__addGoods">
      <div className="admin__addGoods--wrapper">
        <Link
          to="/admin/shop"
          className="admin__back--link"
          onClick={() => {
            setCassette(null);
            setVinyl(null);
            setMerch(null);
          }}
        >
          <AdminBack />
        </Link>
        <div className="admin__addGoods--title">Add Goods</div>
      </div>
      <p className="admin__addGoods--label">Type</p>
      <div className="admin__addGoods--inputWrapper">
        {types === "vinyl" && (
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: type === "vinyl" ? "#ffffff" : "" }}
            onClick={() => {
              setType("vinyl");
            }}
          >
            <div
              className={
                type === "vinyl"
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            Vinyl
          </div>
        )}
        {types === "cassette" && (
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: type === "cassette" ? "#ffffff" : "" }}
            onClick={() => {
              setType("cassette");
            }}
          >
            <div
              className={
                type === "cassette"
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            Casette
          </div>
        )}
        {types === "merch" && (
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: type === "merch" ? "#ffffff" : "" }}
            onClick={() => {
              setType("merch");
            }}
          >
            <div
              className={
                type === "merch"
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            Merch
          </div>
        )}
      </div>
      {type === "merch" && (
        <>
          <p className="admin__addGoods--label">Main info</p>
          <div className="admin__addGoods--inputWrapper">
            <input
              type="text"
              className={
                nameError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Name"
            />
            <input
              type="text"
              className={
                fromError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
              }}
              placeholder="From"
            />
          </div>
        </>
      )}
      <p className="admin__addGoods--label">Availability</p>
      <div className="admin__addGoods--inputWrapper">
        <div
          className="admin__addGoods--input admin__addGoods--radio"
          style={{ color: availability === "inStock" ? "#ffffff" : "" }}
          onClick={() => {
            setAvailability("inStock");
          }}
        >
          <div
            className={
              availability === "inStock"
                ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                : "admin__addGoods--radioSquare"
            }
          ></div>
          In stock
        </div>
        <div
          className="admin__addGoods--input admin__addGoods--radio"
          style={{ color: availability === "preOrder" ? "#ffffff" : "" }}
          onClick={() => {
            setAvailability("preOrder");
          }}
        >
          <div
            className={
              availability === "preOrder"
                ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                : "admin__addGoods--radioSquare"
            }
          ></div>
          Pre-order
        </div>
        <div
          className="admin__addGoods--input admin__addGoods--radio"
          style={{ color: availability === "outOfStock" ? "#ffffff" : "" }}
          onClick={() => {
            setAvailability("outOfStock");
          }}
        >
          <div
            className={
              availability === "outOfStock"
                ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                : "admin__addGoods--radioSquare"
            }
          ></div>
          Out of Stock
        </div>
      </div>
      {availability === "inStock" && type !== "merch" && (
        <>
          <p className="admin__addGoods--label">Amount</p>
          <div className="admin__addGoods--inputWrapper">
            <input
              type="number"
              className="admin__addGoods--input"
              style={{ maxWidth: "245px" }}
              value={amount}
              onChange={(e) => {
                setAmount(+e.target.value);
              }}
            />
          </div>
        </>
      )}
      {type === "merch" && availability === "inStock" && (
        <>
          <p className="admin__addGoods--label">Sizes</p>
          <div className="admin__addGoods--inputWrapper">
            <div
              className="admin__addGoods--input admin__addGoods--radio"
              style={{ color: oneSize ? "#ffffff" : "" }}
              onClick={() => {
                setOneSize((prev) => !prev);
              }}
            >
              <div
                className={
                  oneSize
                    ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                    : "admin__addGoods--radioSquare"
                }
              ></div>
              One-size
            </div>
          </div>
        </>
      )}
      {type === "merch" && (
        <>
          <p className="admin__addGoods--label">Colors</p>
          <div className="admin__addGoods--inputWrapper">
            <div
              className="admin__addGoods--input admin__addGoods--radio"
              style={{ color: oneColor ? "#ffffff" : "" }}
              onClick={() => {
                setOneColor((prev) => !prev);
              }}
            >
              <div
                className={
                  oneColor
                    ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                    : "admin__addGoods--radioSquare"
                }
              ></div>
              One-color
            </div>
          </div>
          {infos.map((_, index) => (
            <div key={index}>
              <div className="admin__addGoods--smallLabelWrapper">
                <p className="admin__addGoods--smallLabel">Color {index + 1}</p>
                <svg
                  onClick={() => {
                    onDeleteColorButtonClick(index);
                  }}
                  style={{ cursor: "pointer" }}
                  width="19"
                  height="24"
                  viewBox="0 0 19 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 1.33333H14.3333L13 0H6.33331L4.99998 1.33333H0.333313V4H19M1.66665 21.3333C1.66665 22.0406 1.9476 22.7189 2.44769 23.219C2.94779 23.719 3.62607 24 4.33331 24H15C15.7072 24 16.3855 23.719 16.8856 23.219C17.3857 22.7189 17.6666 22.0406 17.6666 21.3333V5.33333H1.66665V21.3333Z"
                    fill="#5B4E4C"
                  />
                </svg>
              </div>
              <div className="admin__addGoods--fileWrapper">
                <div className="admin__addGoods--fileThumb dashedBorder">
                  <input
                    type="file"
                    className="admin__addGoods--inputFile"
                    onChange={async (
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      if (e.target.files) {
                        const url = await uploadImage(e.target.files[0]);
                        setInfo((prev) => {
                          const arr = [...prev];
                          arr[index].image = url;
                          return arr;
                        });
                        setOneImage(url);
                        setInfos((prev) => [...prev, 1]);
                        setInfos((prev) => {
                          const arr = [...prev];
                          arr.pop();
                          return arr;
                        });
                      }
                    }}
                  />
                  {info[index].image && (
                    <AdminBin
                      className="admin__image--bin"
                      onClick={() => {
                        onDeleteImageClick(info[index].image);
                        if (index === 0) {
                          setOneImage("");
                        }
                        setInfo((prev) => {
                          const arr = [...prev];
                          arr[index].image = "";
                          return arr;
                        });
                      }}
                    />
                  )}
                  {!info[index].image && (
                    <OtherImg className="admin__addGoods--merchCoverImg" />
                  )}
                  {info[index].image && (
                    <img
                      src={info[index].image}
                      alt="merch"
                      className="admin__addGoods--img"
                    />
                  )}
                </div>
              </div>
              <div
                className="admin__addGoods--inputWrapper"
                style={{ position: "relative", marginTop: "10px" }}
              >
                <input
                  style={{ paddingLeft: "44px" }}
                  className="admin__addGoods--input"
                  placeholder="#000000"
                  value={info[index].color}
                  onChange={(e) => {
                    setInfo((prev) => {
                      const arr = [...prev];
                      arr[index].color = e.target.value;
                      return arr;
                    });
                  }}
                />
                <div
                  className="admin__addGoods--radioSquare admin__addGoods--radioChoosen admin__addGoods--merchSquare"
                  style={{
                    background:
                      info[index].color.length >= 4 ? info[index].color : "",
                  }}
                ></div>
                {!oneSize ? (
                  <>
                    <p className="admin__addGoods--label">XL</p>
                    <input
                      type="number"
                      className="admin__addGoods--input"
                      style={{ maxWidth: "85px" }}
                      value={info[index].xl}
                      onChange={(e) => {
                        setInfo((prev) => {
                          const arr = [...prev];
                          arr[index].xl = +e.target.value;
                          return arr;
                        });
                      }}
                    />
                    <p className="admin__addGoods--label">L</p>
                    <input
                      type="number"
                      className="admin__addGoods--input"
                      style={{ maxWidth: "85px" }}
                      value={info[index].l}
                      onChange={(e) => {
                        setInfo((prev) => {
                          const arr = [...prev];
                          arr[index].l = +e.target.value;
                          return arr;
                        });
                      }}
                    />
                    <p className="admin__addGoods--label">M</p>
                    <input
                      type="number"
                      className="admin__addGoods--input"
                      style={{ maxWidth: "85px" }}
                      value={info[index].m}
                      onChange={(e) => {
                        setInfo((prev) => {
                          const arr = [...prev];
                          arr[index].m = +e.target.value;
                          return arr;
                        });
                      }}
                    />
                    <p className="admin__addGoods--label">S</p>
                    <input
                      type="number"
                      className="admin__addGoods--input"
                      style={{ maxWidth: "85px" }}
                      value={info[index].s}
                      onChange={(e) => {
                        setInfo((prev) => {
                          const arr = [...prev];
                          arr[index].s = +e.target.value;
                          return arr;
                        });
                      }}
                    />
                    <p className="admin__addGoods--label">XS</p>
                    <input
                      type="number"
                      className="admin__addGoods--input"
                      style={{ maxWidth: "85px" }}
                      value={info[index].xs}
                      onChange={(e) => {
                        setInfo((prev) => {
                          const arr = [...prev];
                          arr[index].xs = +e.target.value;
                          return arr;
                        });
                      }}
                    />
                  </>
                ) : (
                  <input
                    type="number"
                    className="admin__addGoods--input"
                    style={{ maxWidth: "245px", marginTop: "10px;" }}
                    value={info[index].oneSizeAmount}
                    onChange={(e) => {
                      setInfo((prev) => {
                        const arr = [...prev];
                        arr[index].oneSizeAmount = +e.target.value;
                        return arr;
                      });
                    }}
                  />
                )}
              </div>
            </div>
          ))}
          {!oneColor && (
            <button
              type="button"
              className="admin__addGoods--colorBtn"
              onClick={() => {
                onAddColorButtonClick();
              }}
            >
              Add color <AddColor className="admin__addGoods--colorBtnSvg" />
            </button>
          )}
          <p className="admin__addGoods--label">Description</p>
          <p className="admin__addGoods--smallLabel">Max 120 symbols</p>
          <div className="admin__addGoods--inputWrapper">
            <input
              type="text"
              className={
                descriptionError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              placeholder="Description"
            />
          </div>
        </>
      )}
      {type !== "merch" && (
        <>
          <p className="admin__addGoods--label">Priority</p>
          <div className="admin__addGoods--inputWrapper">
            <div
              className="admin__addGoods--input admin__addGoods--radio"
              style={{ color: priority === 1 ? "#ffffff" : "" }}
              onClick={() => {
                setPriority(1);
              }}
            >
              <div
                className={
                  priority === 1
                    ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                    : "admin__addGoods--radioSquare"
                }
              ></div>
              1
            </div>
            <div
              className="admin__addGoods--input admin__addGoods--radio"
              style={{ color: priority === 2 ? "#ffffff" : "" }}
              onClick={() => {
                setPriority(2);
              }}
            >
              <div
                className={
                  priority === 2
                    ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                    : "admin__addGoods--radioSquare"
                }
              ></div>
              2
            </div>
          </div>
        </>
      )}
      {type !== "merch" && (
        <>
          <p className="admin__addGoods--label">Showcase</p>
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: showcase ? "#ffffff" : "" }}
            onClick={() => {
              setShowcase((prev) => !prev);
            }}
          >
            <div
              className={
                showcase
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            Add to the showcase
          </div>
        </>
      )}
      {type !== "merch" && (
        <>
          <p className="admin__addGoods--label">Main info</p>
          <div className="admin__addGoods--inputWrapper">
            <input
              type="text"
              className={
                artistNameError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={artistName}
              onChange={(e) => {
                setArtistName(e.target.value);
              }}
              placeholder="Artist Name"
            />
            <input
              type="text"
              className={
                albumNameError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={albumName}
              onChange={(e) => {
                setAlbumName(e.target.value);
              }}
              placeholder="Album Name"
            />
          </div>
        </>
      )}
      {type !== "merch" && (
        <>
          <p className="admin__addGoods--label">Cover Picture</p>
          <div className="admin__addGoods--fileWrapper">
            <div
              className={
                coverPictureError
                  ? "admin__addGoods--fileThumb dashedBorderRed"
                  : "admin__addGoods--fileThumb dashedBorder"
              }
            >
              <input
                type="file"
                className="admin__addGoods--inputFile"
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    const url = await uploadImage(e.target.files[0]);
                    setCoverPicture(url);
                  }
                }}
              />
              {coverPicture && (
                <AdminBin
                  className="admin__image--bin"
                  onClick={() => {
                    onDeleteImageClick(coverPicture);
                    setCoverPicture("");
                  }}
                />
              )}
              {!coverPicture && (
                <CoverImg className="admin__addGoods--coverImg" />
              )}
              {coverPicture && (
                <img
                  src={coverPicture}
                  alt="cover good"
                  className="admin__addGoods--img"
                />
              )}
            </div>
          </div>
        </>
      )}
      {showcase && type !== "merch" && (
        <>
          <p className="admin__addGoods--label">
            {type === "vinyl" ? "Vinyl" : "Cassette"} picture
          </p>
          <div className="admin__addGoods--fileWrapper ">
            <div className="admin__addGoods--fileThumb dashedBorder">
              <input
                type="file"
                className="admin__addGoods--inputFile"
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    const url = await uploadImage(e.target.files[0]);
                    setShowcaseImage(url);
                  }
                }}
              />
              {showcaseImage && (
                <AdminBin
                  className="admin__image--bin"
                  onClick={() => {
                    onDeleteImageClick(showcaseImage);
                    setShowcaseImage("");
                  }}
                />
              )}
              {type === "vinyl" ? (
                <>
                  {!showcaseImage && (
                    <VinylCoverImg className="admin__addGoods--coverImg" />
                  )}
                </>
              ) : (
                <>
                  {!showcaseImage && (
                    <CassetteCoverImg className="admin__addGoods--coverImg" />
                  )}
                </>
              )}
              {showcaseImage && (
                <img
                  src={showcaseImage}
                  alt="cover good"
                  className="admin__addGoods--img"
                />
              )}
            </div>
          </div>
        </>
      )}
      {type !== "merch" && (
        <>
          <p className="admin__addGoods--label">Other Images</p>
          <div className="admin__addGoods--fileWrapper">
            <div className="admin__addGoods--fileThumb dashedBorder">
              <input
                type="file"
                className="admin__addGoods--inputFile"
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    const url = await uploadImage(e.target.files[0]);
                    setOtherPicture1(url);
                  }
                }}
              />
              {otherPicture1 && (
                <AdminBin
                  className="admin__image--bin"
                  onClick={() => {
                    onDeleteImageClick(otherPicture1);
                    setOtherPicture1("");
                  }}
                />
              )}
              {!otherPicture1 && (
                <OtherImg className="admin__addGoods--coverImg" />
              )}
              {otherPicture1 && (
                <img
                  src={otherPicture1}
                  alt="cover good"
                  className="admin__addGoods--img"
                />
              )}
            </div>
            <div className="admin__addGoods--fileThumb dashedBorder">
              <input
                type="file"
                className="admin__addGoods--inputFile"
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    const url = await uploadImage(e.target.files[0]);
                    setOtherPicture2(url);
                  }
                }}
              />
              {otherPicture2 && (
                <AdminBin
                  className="admin__image--bin"
                  onClick={() => {
                    onDeleteImageClick(otherPicture2);
                    setOtherPicture2("");
                  }}
                />
              )}
              {!otherPicture2 && (
                <OtherImg className="admin__addGoods--coverImg" />
              )}
              {otherPicture2 && (
                <img
                  src={otherPicture2}
                  alt="cover good"
                  className="admin__addGoods--img"
                />
              )}
            </div>
            <div className="admin__addGoods--fileThumb dashedBorder">
              <input
                type="file"
                className="admin__addGoods--inputFile"
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    const url = await uploadImage(e.target.files[0]);
                    setOtherPicture3(url);
                  }
                }}
              />
              {otherPicture3 && (
                <AdminBin
                  className="admin__image--bin"
                  onClick={() => {
                    onDeleteImageClick(otherPicture3);
                    setOtherPicture3("");
                  }}
                />
              )}
              {!otherPicture3 && (
                <OtherImg className="admin__addGoods--coverImg" />
              )}
              {otherPicture3 && (
                <img
                  src={otherPicture3}
                  alt="cover good"
                  className="admin__addGoods--img"
                />
              )}
            </div>
            <div className="admin__addGoods--fileThumb dashedBorder">
              <input
                type="file"
                className="admin__addGoods--inputFile"
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    const url = await uploadImage(e.target.files[0]);
                    setOtherPicture4(url);
                  }
                }}
              />
              {otherPicture4 && (
                <AdminBin
                  className="admin__image--bin"
                  onClick={() => {
                    onDeleteImageClick(otherPicture4);
                    setOtherPicture4("");
                  }}
                />
              )}
              {!otherPicture4 && (
                <OtherImg className="admin__addGoods--coverImg" />
              )}
              {otherPicture4 && (
                <img
                  src={otherPicture4}
                  alt="cover good"
                  className="admin__addGoods--img"
                />
              )}
            </div>
          </div>
        </>
      )}
      {type !== "merch" && (
        <>
          <p className="admin__addGoods--label">Additional info</p>
          <div className="admin__addGoods--inputWrapper">
            <input
              type="text"
              className={
                labelNameError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={labelName}
              onChange={(e) => {
                setLabelName(e.target.value);
              }}
              placeholder="Label name"
            />
            <input
              type="text"
              className={
                issueError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={issue}
              onChange={(e) => {
                setIssue(e.target.value);
              }}
              placeholder="Issue (Year, Сolor, LPs)"
            />
          </div>
        </>
      )}
      {type !== "merch" && (
        <div className="admin__addGoods--inputWrapper admin__addGoods--spaceT">
          <input
            type="text"
            className={
              countryError
                ? "admin__addGoods--input admin__addGoods--inputError"
                : "admin__addGoods--input"
            }
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            placeholder="Country"
          />
          <input
            type="text"
            className={
              genreError
                ? "admin__addGoods--input admin__addGoods--inputError"
                : "admin__addGoods--input"
            }
            value={genre}
            onChange={(e) => {
              setGenre(e.target.value);
            }}
            placeholder="Genre/Style"
          />
        </div>
      )}
      <p className="admin__addGoods--label">Price</p>
      <div
        className="admin__addGoods--input admin__addGoods--radio"
        style={{ color: showcase ? "#ffffff" : "", marginBottom: "10px" }}
        onClick={() => {
          setSale((prev) => !prev);
        }}
      >
        <div
          className={
            sale
              ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
              : "admin__addGoods--radioSquare"
          }
        ></div>
        Sale
      </div>
      {sale && (
        <>
          <p className="admin__addGoods--label">Before sale</p>
          <div className="admin__addGoods--inputWrapper">
            <div className="admin__addGoods--priceWrapper">
              <input
                type="number"
                className="admin__addGoods--input"
                value={uahSale}
                onChange={(e) => {
                  setUahSale(+e.target.value);
                }}
              />
              <p className="admin__addGoods--currency">UAH</p>
            </div>
            <div className="admin__addGoods--priceWrapper">
              <input
                type="number"
                className="admin__addGoods--input"
                value={usdSale}
                onChange={(e) => {
                  setUsdSale(+e.target.value);
                }}
              />
              <p className="admin__addGoods--currency">USD</p>
            </div>
          </div>
          <p className="admin__addGoods--label">After sale</p>
        </>
      )}
      <div className="admin__addGoods--inputWrapper">
        <div className="admin__addGoods--priceWrapper">
          <input
            type="number"
            className="admin__addGoods--input"
            value={uah}
            onChange={(e) => {
              setUah(+e.target.value);
            }}
          />
          <p className="admin__addGoods--currency">UAH</p>
        </div>
        <div className="admin__addGoods--priceWrapper">
          <input
            type="number"
            className="admin__addGoods--input"
            value={usd}
            onChange={(e) => {
              setUsd(+e.target.value);
            }}
          />
          <p className="admin__addGoods--currency">USD</p>
        </div>
      </div>
      {type !== "merch" && (
        <>
          <p className="admin__addGoods--label">Tracklist</p>
          <div className="admin__addGoods--inputWrapper admin__addGoods--spaceT">
            <input
              type="text"
              className="admin__addGoods--input"
              value={spotifyCode}
              onChange={(e) => {
                setSpotifyCode(e.target.value);
              }}
              placeholder="Spotify code"
            />
            <p className="admin__addGoods--or">Or</p>
            <input
              type="text"
              className="admin__addGoods--input"
              value={tracklist}
              onChange={(e) => {
                setTracklist(e.target.value);
              }}
              placeholder="Tracklist (paste list) "
            />
          </div>
        </>
      )}
      <p className="admin__addGoods--label">You might like it (if manual)</p>
      <div className="admin__addGoods--inputWrapper admin__addGoods--spaceT">
        <input
          type="text"
          className="admin__addGoods--input"
          value={link1}
          onChange={(e) => {
            setLink1(e.target.value);
          }}
          placeholder="link"
        />
        <input
          type="text"
          className="admin__addGoods--input"
          value={link2}
          onChange={(e) => {
            setLink2(e.target.value);
          }}
          placeholder="link"
        />
        <input
          type="text"
          className="admin__addGoods--input"
          value={link3}
          onChange={(e) => {
            setLink3(e.target.value);
          }}
          placeholder="link"
        />
      </div>
      <p className="admin__addGoods--label">CEO tags</p>
      <div className="admin__addGoods--inputWrapper admin__addGoods--spaceT">
        <input
          type="text"
          className="admin__addGoods--input"
          value={tags}
          onChange={(e) => {
            setTags(e.target.value);
          }}
          placeholder="Tags"
        />
      </div>
      <button
        type="button"
        className="admin__addGoods--submit"
        onClick={() => {
          onSubmitButtonClick();
        }}
      >
        Зберегти зміни
      </button>
    </div>
  );
};
