import { Title } from "../../components/Title/Title";
import performer from "../../assets/images/performer.png";
import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";
import { ReactComponent as Instagram } from "../../assets/images/instagram.svg";
import album1 from "../../assets/images/album1.png";
import album2 from "../../assets/images/album2.png";
import { ReactComponent as Spotify } from "../../assets/images/spotify.svg";
import { ReactComponent as AppleMusic } from "../../assets/images/appleMusic.svg";
import { ReactComponent as Deezer } from "../../assets/images/deezer.svg";
import { ReactComponent as Bandcamp } from "../../assets/images/bandcamp.svg";
import { ReactComponent as Play } from "../../assets/images/play.svg";
import recommendation1 from "../../assets/images/recommendation1.png";
import recommendation2 from "../../assets/images/recommendation2.png";
import { useEffect, useState } from "react";
import { Artist } from "../../interfaces/artist";
import { getArtist } from "../../services/axios";
import { useParams } from "react-router-dom";

export const Performer = () => {
  const [direction, setDirection] = useState(true);
  const [artist, setArtist] = useState<Artist | null>(null);
  const [language, setLanguage] = useState("uk");

  const { id } = useParams();

  useEffect(() => {
    const fetchArtist = async () => {
      setArtist(await getArtist(id, language));
    };

    fetchArtist();
  }, [language, id]);

  return (
    <section className="performer">
      <div className="container__two">
        <Title
          title="Виконавець"
          type={false}
          direction={direction}
          setDirection={setDirection}
        />
        <img src={artist?.image} alt="performer" className="performer__image" />
        <h2 className="performer__title">{artist?.albumName}</h2>
        {(artist?.telegramLink || artist?.instagramLink) && (
          <div className="performer__social">
            {artist?.instagramLink && (
              <a
                href={artist?.instagramLink}
                className="performer__social--link"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram className="performer__social--svg" />
              </a>
            )}
            {artist?.telegramLink && (
              <a
                href={artist?.telegramLink}
                className="performer__social--link"
                target="_blank"
                rel="noreferrer"
              >
                <Telegram className="performer__social--svg" />
              </a>
            )}
          </div>
        )}
        <p className="performer__description">{artist?.description}</p>
        <div className="performer__albums">
          {artist?.albumName.map((_, index) => (
            <div className="performer__album">
              <h3 className="performer__album--title">
                {artist.albumName[index]}
              </h3>
              <div className="performer__album--thumb">
                <img
                  src={artist.albumImage[index]}
                  alt={artist.albumName[index]}
                  className="performer__album--image"
                />
                <ul className="performer__album--list">
                  {artist.spotifyLink[index] && (
                    <li className="performer__album--item">
                      <a
                        href={artist.spotifyLink[index]}
                        className="performer__album--social"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="performer__album--wrapper">
                          <Spotify className="performer__album--svg" />
                          Spotify
                        </div>
                        <Play className="performer__album--play" />
                      </a>
                    </li>
                  )}
                  {artist.appleMusicLink[index] && (
                    <li className="performer__album--item">
                      <a
                        href={artist.appleMusicLink[index]}
                        className="performer__album--social"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="performer__album--wrapper">
                          <AppleMusic className="performer__album--svg" />
                          Apple Music
                        </div>
                        <Play className="performer__album--play" />
                      </a>
                    </li>
                  )}
                  {artist.deezerLink[index] && (
                    <li className="performer__album--item">
                      <a
                        href={artist.deezerLink[index]}
                        className="performer__album--social"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="performer__album--wrapper">
                          <Deezer className="performer__album--svg" />
                          Deezer
                        </div>
                        <Play className="performer__album--play" />
                      </a>
                    </li>
                  )}
                  {artist.bandcampLink[index] && (
                    <li className="performer__album--item">
                      <a
                        href={artist.bandcampLink[index]}
                        className="performer__album--social"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="performer__album--wrapper">
                          <Bandcamp className="performer__album--svg" />
                          Bandcamp
                        </div>
                        <Play className="performer__album--play" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <p className="performer__bottom--text">В магазині:</p>
        {/* <ul className="recommendations__list">
          {data2.map(({ image, author, name, price, quantity }) => (
            <li className="recommendations__item">
              <img
                src={image}
                alt={author + " " + name}
                className="recommendations__image"
              />
              <p className="recommendations__author">{author}</p>
              <p className="recommendations__name">{name}</p>
              <p className="recommendations__price">{price}</p>
              <button
                type="button"
                className={
                  quantity === "preorder" || quantity === "order"
                    ? "recommendations__button recommendations__button--hover"
                    : "recommendations__button"
                }
                style={
                  quantity === "preorder" || quantity === "order"
                    ? { color: "#251F1E", background: "#FFFFFF" }
                    : { color: "#ffffff", background: "#FB4839" }
                }
              >
                {quantity === "preorder"
                  ? "Передзамовити"
                  : quantity === "order"
                  ? "Придбати"
                  : "Нема в наявності"}
              </button>
            </li>
          ))}
        </ul> */}
      </div>
    </section>
  );
};
