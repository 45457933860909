import { useEffect, useState } from "react";
import {
  deleteShowcase,
  getAllShowcases,
  updateShowcase,
} from "../../services/axios";
import { ReactComponent as TopArrow } from "../../assets/images/showcaseTopArrow.svg";
import { ReactComponent as BottomArrow } from "../../assets/images/showcaseBottomArrow.svg";
import { Vinyl } from "../../interfaces/vinyl";

export const ShowcaseList = ({ language }: { language: string }) => {
  const [showcases, setShowcases] = useState<Vinyl[] | []>([]);
  useEffect(() => {
    const fetchShowcases = async () => {
      setShowcases(await getAllShowcases(language));
    };

    fetchShowcases();
  }, [language]);

  const refetchShowcase = async () => {
    setShowcases(await getAllShowcases(language));
  };

  const moveUp = (index: number) => {
    if (index === 0) return;
    const updatedArray = [...showcases];
    const temp = updatedArray[index];
    updatedArray[index] = updatedArray[index - 1];
    updatedArray[index - 1] = temp;

    updatedArray[index].order = index + 1;
    updatedArray[index - 1].order = index;

    for (const arr of updatedArray) {
      updateShowcase({ _id: arr._id, order: arr.order });
    }

    setTimeout(() => {
      refetchShowcase();
    }, 500);
  };

  const moveDown = (index: number) => {
    if (index === showcases.length - 1) return;
    const updatedArray = [...showcases];
    const temp = updatedArray[index];
    updatedArray[index] = updatedArray[index + 1];
    updatedArray[index + 1] = temp;

    updatedArray[index].order = index + 1;
    updatedArray[index + 1].order = index + 2;

    for (const arr of updatedArray) {
      updateShowcase({ _id: arr._id, order: arr.order });
    }

    setTimeout(() => {
      refetchShowcase();
    }, 500);
  };

  return (
    <div className="showcase">
      <ul className="showcase__list">
        {showcases.map(
          (
            { _id, availability, amount, coverPicture, artistName, albumName },
            index
          ) => (
            <li className="showcase__item" key={_id}>
              <p className="showcase__index">#{index + 1}</p>
              <div className="showcase__top--wrapper">
                <div className="showcase__top--thumb">
                  <p className="showcase__top--text">Move</p>
                  <div className="showcase__arrow--wrapper">
                    <TopArrow
                      onClick={() => moveUp(index)}
                      style={{ cursor: "pointer" }}
                    />
                    <BottomArrow
                      onClick={() => moveDown(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <p
                  className="showcase__top--delete"
                  onClick={() => {
                    deleteShowcase(_id);
                    setTimeout(() => {
                      refetchShowcase();
                    }, 500);
                  }}
                >
                  Delete from showcase
                </p>
              </div>
              <div className="admin__artist--item" style={{ marginTop: "1px" }}>
                <img
                  src={coverPicture}
                  alt={artistName}
                  className="admin__artist--image"
                />
                <div
                  style={{
                    justifyContent: "space-between",
                    paddingRight: "10px",
                    paddingTop: "10px",
                  }}
                  className="admin__artist--artistNameWrapper"
                >
                  <div
                    className="admin__goodsList--artistNameWrapper"
                    style={{ paddingTop: 0 }}
                  >
                    <p className="admin__goodsList--artistName">{artistName}</p>
                    <p className="admin__goodsList--albumName">{albumName}</p>
                  </div>
                  <div
                    className="admin__goodsList--additional"
                    style={{
                      paddingTop: 0,
                      paddingBottom: "10px",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <p className="admin__goodsList--id">{_id}</p>
                    <p
                      className="admin__goodsList--amount"
                      style={{
                        color:
                          availability === "inStock"
                            ? "#35AFA3"
                            : availability === "preOrder"
                            ? "#F5CD22"
                            : "#FB4839",
                      }}
                    >
                      {availability === "inStock"
                        ? amount
                        : availability === "preOrder"
                        ? "Pre-order"
                        : "Out of stock"}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
};
