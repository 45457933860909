import { Suspense } from "react";
import { Header } from "../Header/Header";
import { Outlet } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import { Footer } from "../Footer/Footer";

export const SharedLayout = () => {
  return (
    <>
      <div className="container">
        <Header />
        <Suspense fallback={<Loader />}>
          <main className="main">
            <Outlet />
          </main>
        </Suspense>
      </div>
      <Footer />
    </>
  );
};
