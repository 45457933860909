import { useEffect, useState } from "react";
import {
  archiveArtist,
  archiveCassette,
  deleteArtist,
  deleteCassette,
  getAllCassettes,
} from "../../services/axios";
import { ReactComponent as AdminEdit } from "../../assets/images/adminEdit.svg";
import { ReactComponent as AdminArchive } from "../../assets/images/adminArchive.svg";
import { ReactComponent as AdminDelete } from "../../assets/images/adminDelete.svg";
import { Cassette } from "../../interfaces/cassette";

interface CassetteProps {
  language: string;
  setCassette: React.Dispatch<React.SetStateAction<Cassette | null>>;
}

export const CassetteList = ({ language, setCassette }: CassetteProps) => {
  const [cassettes, setCassettes] = useState([]);

  useEffect(() => {
    const fetchAllCassettes = async () => {
      const data = await getAllCassettes(language);
      setCassettes(data);
    };

    fetchAllCassettes();
  }, [language]);

  const refetchCassettes = async () => {
    const data = await getAllCassettes(language);
    setCassettes(data);
  };

  return (
    <ul className="admin__artist--list">
      {cassettes.map(
        (
          {
            _id,
            availability,
            amount,
            artistName,
            albumName,
            coverPicture,
            archived,
            adapted,
          },
          index
        ) => (
          <li className="admin__artist--item" key={_id}>
            <img
              src={coverPicture}
              alt={artistName}
              className="admin__artist--image"
            />
            <div
              style={{
                justifyContent: "space-between",
                paddingRight: "65px",
                paddingTop: "20px",
              }}
              className={
                archived
                  ? "admin__artist--artistNameWrapper admin__artist--artistNameWrapperArchived"
                  : "admin__artist--artistNameWrapper"
              }
            >
              {!adapted && (
                <div className="admin__artist--adapted">Не адаптовано!</div>
              )}
              <div className="admin__goodsList--artistNameWrapper">
                <p className="admin__goodsList--artistName">{artistName}</p>
                <p className="admin__goodsList--albumName">{albumName}</p>
              </div>
              <div className="admin__goodsList--additional">
                <p className="admin__goodsList--id">{_id}</p>
                <p
                  className="admin__goodsList--amount"
                  style={{
                    color:
                      availability === "inStock"
                        ? "#35AFA3"
                        : availability === "preOrder"
                        ? "#F5CD22"
                        : "#FB4839",
                  }}
                >
                  {availability === "inStock"
                    ? amount
                    : availability === "preOrder"
                    ? "Pre-order"
                    : "Out of stock"}
                </p>
              </div>
              <div className="admin__artist--action">
                <AdminEdit
                  className="admin__artist--labelList"
                  onClick={() => {
                    setCassette(cassettes[index]);
                  }}
                />
                <AdminArchive
                  className="admin__artist--labelList"
                  onClick={() => {
                    archiveCassette(_id, !archived);
                    setTimeout(() => {
                      refetchCassettes();
                    }, 500);
                  }}
                />
                <AdminDelete
                  className="admin__artist--labelList"
                  onClick={() => {
                    deleteCassette(_id);
                    setTimeout(() => {
                      refetchCassettes();
                    }, 500);
                  }}
                />
              </div>
            </div>
          </li>
        )
      )}
    </ul>
  );
};
