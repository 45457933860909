import { Link } from "react-router-dom";
import { Merch } from "../../interfaces/merch";

export const MerchList = ({
  data,
  direction,
}: {
  data: Merch[] | [];
  direction: boolean;
}) => {
  return (
    <ul
      className={
        direction
          ? "productsList__list"
          : "productsList__list productsList__list--type2"
      }
    >
      {data?.map(({ _id, info, name, from, uah, uahSale }, index) => (
        <li
          key={index}
          className={
            direction
              ? "productsList__item productsList__item--type1"
              : "productsList__item productsList__item--type2"
          }
        >
          <img src={info[0].image} alt={name} className="productsList__image" />
          <div className="productsList__thumb">
            <p
              className="productsList__author"
              style={
                direction ? { textAlign: "center" } : { textAlign: "start" }
              }
            >
              {name}
            </p>
            <p
              className="productsList__name"
              style={
                direction ? { textAlign: "center" } : { textAlign: "start" }
              }
            >
              {from}
            </p>
            <div
              className="productsList__price--wrapper"
              style={
                direction
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {uahSale !== 0 && (
                <p
                  className="productsList__price--sale"
                  style={
                    direction ? { textAlign: "center" } : { textAlign: "start" }
                  }
                >
                  <s>1650 грн.</s>
                </p>
              )}
              <p
                className="productsList__price"
                style={
                  direction ? { textAlign: "center" } : { textAlign: "start" }
                }
              >
                {uah}
              </p>
            </div>
            <Link to={`/release/merch/${_id}`}>
              <button type="button" className="productsList__button">
                Придбати
              </button>
            </Link>
          </div>
        </li>
      ))}
    </ul>
  );
};
