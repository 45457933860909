import { Title } from "../../components/Title/Title";
import label1 from "../../assets/images/label1.png";
import label2 from "../../assets/images/label2.png";
import label3 from "../../assets/images/label3.png";
import { useEffect, useState } from "react";
import { Artist } from "../../interfaces/artist";
import { getAllArtists } from "../../services/axios";
import { Link } from "react-router-dom";

export const Label = () => {
  const [artists, setArtists] = useState<Artist[] | []>([]);
  const [language, setLanguage] = useState("uk");

  useEffect(() => {
    const fetchAllArtists = async () => {
      const data = await getAllArtists(language);
      setArtists(data);
    };

    fetchAllArtists();
  }, [language]);

  return (
    <section className="label">
      <div className="container__two">
        <Title title="Лейбл" type={false} />
        <ul className="label__list">
          {artists.map(({ _id, artistName, image }, index) => (
            <Link to={`/performer/${_id}`}>
              <li key={_id} className="label__item">
                <div className="label__image--wrapper">
                  <img src={image} alt={artistName} className="label__image" />
                </div>
                <div className="label__name">{artistName}</div>
              </li>
            </Link>
          ))}
        </ul>
        <p className="label__text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et
          consectetur dui. Sed ultricies dui et viverra ultricies. Vivamus
          convallis varius aliquet. Cras a viverra massa. Donec purus nisi,
          tristique a faucibus nec, tempor non nisl. Quisque lacinia ac tellus
          sed venenatis. Praesent varius nisi et tortor convallis, vitae
          tristique felis vehicula. Maecenas eros lorem, dignissim eget suscipit
          sed, pretium ut mauris. Morbi iaculis dolor vitae libero euismod
          ultrices. Curabitur sit amet libero eu diam faucibus gravida. Sed vel
          leo congue, fringilla sapien et, vestibulum quam. Integer vitae
          suscipit purus. Praesent vel placerat sapien, nec egestas nisl.{" "}
        </p>
      </div>
    </section>
  );
};
