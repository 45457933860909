import { About } from "../../components/About/About";
import { Hero } from "../../components/Hero/Hero";
import { Title } from "../../components/Title/Title";

export const Home = () => {
  return (
    <div className="home">
      <div className="container__two">
        <Title title="DO YOU WANNA LO-FI?" type={false} />
        <Hero />
        <About />
      </div>
    </div>
  );
};
