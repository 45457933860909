import { useEffect, useState } from "react";
import { Title } from "../../components/Title/Title";
import merch from "../../assets/images/merch1.png";
import { Sort } from "../../components/Sort/Sort";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { getAllCassettes, getAllMerchs } from "../../services/axios";
import { Merch } from "../../interfaces/merch";
import { MerchList } from "../../components/MerchList/MerchList";

export const MerchComp = () => {
  const [direction, setDirection] = useState(true);
  const [inStock, setInStock] = useState(true);
  const [data, setData] = useState<Merch[] | []>([]);
  const [language, setLanguage] = useState("uk");

  useEffect(() => {
    const fetchAllMerchs = async () => {
      const data = await getAllMerchs(language);
      setData(data);
    };

    fetchAllMerchs();
  }, [language]);
  return (
    <div className="cassette">
      <div className="container__two">
        <Title
          title="Мерч"
          type={true}
          direction={direction}
          setDirection={setDirection}
        />
        <Sort />
        <MerchList data={data} direction={direction} />
      </div>
    </div>
  );
};
