import { useEffect, useState } from "react";
import { archiveMerch, deleteMerch, getAllMerchs } from "../../services/axios";
import { ReactComponent as AdminEdit } from "../../assets/images/adminEdit.svg";
import { ReactComponent as AdminArchive } from "../../assets/images/adminArchive.svg";
import { ReactComponent as AdminDelete } from "../../assets/images/adminDelete.svg";
import { Merch } from "../../interfaces/merch";

interface MerchProps {
  language: string;
  setMerch: React.Dispatch<React.SetStateAction<Merch | null>>;
}

export const MerchList = ({ language, setMerch }: MerchProps) => {
  const [merchs, setMerchs] = useState<Merch[]>([]);

  useEffect(() => {
    const fetchAllMerchs = async () => {
      const data: Merch[] = await getAllMerchs(language);
      setMerchs(data);
    };

    fetchAllMerchs();
  }, [language]);

  const refetchMerchs = async () => {
    const data: Merch[] = await getAllMerchs(language);
    setMerchs(data);
  };

  return (
    <ul className="admin__artist--list">
      {merchs.map(
        (
          { _id, availability, amount, name, from, info, archived, adapted },
          index
        ) => (
          <li className="admin__artist--item" key={_id}>
            <img
              src={info && info[0] && info[0].image}
              alt={name}
              className="admin__artist--image"
            />
            <div
              style={{
                justifyContent: "space-between",
                paddingRight: "65px",
                paddingTop: "20px",
              }}
              className={
                archived
                  ? "admin__artist--artistNameWrapper admin__artist--artistNameWrapperArchived"
                  : "admin__artist--artistNameWrapper"
              }
            >
              {!adapted && (
                <div className="admin__artist--adapted">Не адаптовано!</div>
              )}
              <div className="admin__goodsList--artistNameWrapper">
                <p className="admin__goodsList--artistName">{name}</p>
                <p className="admin__goodsList--albumName">{from}</p>
              </div>
              <div className="admin__goodsList--additional">
                <p className="admin__goodsList--id">{_id}</p>
                <p
                  className="admin__goodsList--amount"
                  style={{
                    color:
                      availability === "inStock"
                        ? "#35AFA3"
                        : availability === "preOrder"
                        ? "#F5CD22"
                        : "#FB4839",
                  }}
                >
                  {availability === "inStock"
                    ? amount
                    : availability === "preOrder"
                    ? "Pre-order"
                    : "Out of stock"}
                </p>
              </div>
              <div className="admin__artist--action">
                <AdminEdit
                  className="admin__artist--labelList"
                  onClick={() => {
                    setMerch(merchs[index]);
                  }}
                />
                <AdminArchive
                  className="admin__artist--labelList"
                  onClick={() => {
                    archiveMerch(_id, !archived);
                    setTimeout(() => {
                      refetchMerchs();
                    }, 500);
                  }}
                />
                <AdminDelete
                  className="admin__artist--labelList"
                  onClick={() => {
                    deleteMerch(_id);
                    setTimeout(() => {
                      refetchMerchs();
                    }, 500);
                  }}
                />
              </div>
            </div>
          </li>
        )
      )}
    </ul>
  );
};
