import { useState } from "react";
import { ReactComponent as Arrow } from "../../assets/images/arrow.svg";

export const Sort = () => {
  const [inStock, setInStock] = useState(true);

  return (
    <div className="sort__wrapper">
      <div>
        <button
          type="button"
          className={
            inStock ? "sort__button--stock" : "sort__button--stock active"
          }
          onClick={() => {
            setInStock(true);
          }}
        >
          Усі
        </button>
        <button
          type="button"
          className={
            inStock ? "sort__button--stock2 active" : "sort__button--stock2"
          }
          onClick={() => {
            setInStock(false);
          }}
        >
          В наявності
        </button>
      </div>
      <div className="sort__sorting2--wrapper">
        <p className="sort__sorting2--text">Сортування</p>
        <Arrow />
      </div>
      <div className="sort__sorting--wrapper">
        <button type="button" className="sort__sorting--button">
          Сортувати:
        </button>
        <button type="button" className="sort__sorting--type">
          За замовчуванням
        </button>
      </div>
    </div>
  );
};
