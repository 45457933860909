import { Swiper, SwiperSlide } from "swiper/react";
import { Title } from "../../components/Title/Title";
import release1 from "../../assets/images/release1.png";
import "swiper/css";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import { useEffect, useState } from "react";
import { getAllCassettes, getCassette, getVinyl } from "../../services/axios";
import { Cassette } from "../../interfaces/cassette";
import { Vinyl } from "../../interfaces/vinyl";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/Loader/Loader";

SwiperCore.use([Navigation, Pagination]);
export const Release = () => {
  const [direction, setDirection] = useState(true);
  const [data, setData] = useState<Vinyl | Cassette | null>(null);
  const [language, setLanguage] = useState("uk");

  const { id } = useParams();

  useEffect(() => {
    const fetchItem = async () => {
      const itemVinyl = await getVinyl(id, language);
      if (itemVinyl) {
        setData(itemVinyl);
      } else {
        const itemCassette = await getCassette(id, language);
        setData(itemCassette);
      }
    };

    fetchItem();
  }, [language, id]);

  const addToCart = () => {
    const userCartTypes = localStorage.getItem("userCartTypes");
    if (userCartTypes !== null) {
      const types = JSON.parse(userCartTypes);
      types.push(data?.type);
      localStorage.setItem("userCartTypes", JSON.stringify(types));
    } else {
      localStorage.setItem("userCartTypes", JSON.stringify([data?.type]));
    }
    const userCartColors = localStorage.getItem("userCartColors");
    if (userCartColors !== null) {
      const colors = JSON.parse(userCartColors);
      colors.push("");
      localStorage.setItem("userCartColors", JSON.stringify(colors));
    } else {
      localStorage.setItem("userCartColors", JSON.stringify([""]));
    }
    const userCartSizes = localStorage.getItem("userCartSizes");
    if (userCartSizes !== null) {
      const sizes = JSON.parse(userCartSizes);
      sizes.push("");
      localStorage.setItem("userCartSizes", JSON.stringify(sizes));
    } else {
      localStorage.setItem("userCartSizes", JSON.stringify([""]));
    }

    const userCartQuantities = localStorage.getItem("userCartQuantities");
    if (userCartQuantities !== null) {
      const quantities = JSON.parse(userCartQuantities);
      quantities.push(1);
      localStorage.setItem("userCartQuantities", JSON.stringify(quantities));
    } else {
      localStorage.setItem("userCartQuantities", JSON.stringify([1]));
    }
  };

  const onButtonBuyClick = () => {
    const userCartIds = localStorage.getItem("userCartIds");
    if (userCartIds !== null) {
      const ids = JSON.parse(userCartIds);
      if (ids.includes(id)) {
        setTimeout(() => {
          window.location.href = "/cart";
        }, 500);
      } else {
        ids.push(id);
        localStorage.setItem("userCartIds", JSON.stringify(ids));
        addToCart();
      }
    } else {
      localStorage.setItem("userCartIds", JSON.stringify([id]));
      addToCart();
    }

    setTimeout(() => {
      window.location.href = "/cart";
    }, 500);
  };

  return data ? (
    <section className="release">
      <div className="container__two">
        <Title
          title="Реліз"
          type={false}
          direction={direction}
          setDirection={setDirection}
        />
        <div className="release__container">
          {data && (
            <Swiper
              className="swiper release__swiper"
              spaceBetween={2500}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              allowTouchMove={false}
              loop={true}
              pagination={{
                clickable: true,
              }}
              slidesPerView={1}
              onSlideChange={() => {}}
              onSwiper={(swiper: any) => {}}
            >
              <SwiperSlide>
                <img
                  src={data?.coverPicture}
                  alt={data?.albumName}
                  className="release__image"
                />
              </SwiperSlide>
              {data?.otherPictures.map((item: string) => (
                <SwiperSlide>
                  <img
                    src={item}
                    alt={data?.albumName}
                    className="release__image"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <div className="release__wrapper">
            <h2 className="release__title">
              {data?.artistName} - {data?.albumName}
            </h2>
            <p
              className="release__availability"
              style={{
                color:
                  data?.availability === "inStock"
                    ? "#35AFA3"
                    : data?.availability === "preOrder"
                    ? "#F5CD22"
                    : "#FB4839",
              }}
            >
              {data?.availability === "inStock"
                ? "В наявності"
                : data?.availability === "preOrder"
                ? "Передзамовлення"
                : "Немає в наявності"}
            </p>
            <p className="release__subtitle">
              Лейбл: <span className="release__span">{data?.labelName}</span>
            </p>
            <p className="release__subtitle">
              Видання:
              <span className="release__span"> {data?.issue}</span>
            </p>
            <p className="release__subtitle">
              Виробник: <span className="release__span">{data?.country}</span>
            </p>
            <p className="release__subtitle">
              Жанр, стиль: <span className="release__span">{data?.genre}</span>
            </p>
            <p className="release__price">
              {data?.uah} грн.
              {data?.uahSale !== 0 && (
                <span className="release__price--sale">
                  <s>{data?.uahSale} грн.</s>
                </span>
              )}
            </p>
            <button
              type="button"
              className="release__button"
              onClick={() => {
                onButtonBuyClick();
              }}
            >
              Придбати
            </button>
            {data?.tracklist && (
              <div
                className="release__tracklist"
                dangerouslySetInnerHTML={{ __html: data?.tracklist }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </section>
  ) : (
    <Loader />
  );
};
