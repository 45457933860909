import { useEffect, useState } from "react";
import { Title } from "../../components/Title/Title";
import vinyl from "../../assets/images/vinyl1.png";
import { Sort } from "../../components/Sort/Sort";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { getAllVinyls } from "../../services/axios";
import { Vinyl } from "../../interfaces/vinyl";

export const VinylComp = () => {
  const [inStock, setInStock] = useState(true);
  const [direction, setDirection] = useState(true);
  const [data, setData] = useState<Vinyl[] | []>([]);
  const [language, setLanguage] = useState("uk");

  useEffect(() => {
    const fetchAllVinyls = async () => {
      const data = await getAllVinyls(language);
      setData(data);
    };

    fetchAllVinyls();
  }, [language]);

  // const data = [
  //   {
  //     image: vinyl,
  //     author: "King Gizzard & Lizard Wizzard",
  //     name: "PetroDragonic Apocalypse",
  //     price: "1700 грн.",
  //     quantity: "preorder",
  //   },
  //   {
  //     image: vinyl,
  //     author: "King Gizzard & Lizard Wizzard",
  //     name: "PetroDragonic Apocalypse",
  //     price: "1700 грн.",
  //     quantity: "preorder",
  //   },
  // ];
  return (
    <div className="vinyl">
      <div className="container__two">
        <Title
          title="Вініл"
          type={true}
          direction={direction}
          setDirection={setDirection}
        />
        <Sort />
        <ProductsList data={data} direction={direction} />
      </div>
    </div>
  );
};
