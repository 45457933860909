import ukraine from "../../assets/images/ukraine.png";

export const About = () => {
  return (
    <section className="about">
      <div className="container__two">
        <h2 className="about__title">Про нас</h2>
        <p className="about__text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et
          consectetur dui. Sed ultricies dui et viverra ultricies. Vivamus
          convallis varius aliquet. Cras a viverra massa. Donec purus nisi,
          tristique a faucibus nec, tempor non nisl. Quisque lacinia ac tellus
          sed venenatis. Praesent varius nisi et tortor convallis, vitae
          tristique felis vehicula. Maecenas eros lorem, dignissim eget suscipit
          sed, pretium ut mauris. Morbi iaculis dolor vitae libero euismod
          ultrices. Curabitur sit amet libero eu diam faucibus gravida. Sed vel
          leo congue, fringilla sapien et, vestibulum quam. Integer vitae
          suscipit purus. Praesent vel placerat sapien, nec egestas nisl.
        </p>
        <div className="about__wrapper">
          <img src={ukraine} alt="Україна" className="about__image" />
          <div className="about__thumb">
            <p className="about__info">STAND WITH UKRAINE</p>
            <p className="about__description">Підтримуй українську армію!</p>
            <a href="https://savelife.in.ua/" className="about__button">
              Donate now!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
