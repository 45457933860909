import { ReactComponent as LeftArrow } from "../../assets/images/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/rightArrow.svg";
import hero from "../../assets/images/hero.png";
import hero2 from "../../assets/images/hero2.png";
import hero3 from "../../assets/images/hero3.png";
import hero4 from "../../assets/images/hero4.png";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { Vinyl } from "../../interfaces/vinyl";
import { Cassette } from "../../interfaces/cassette";
import { getAllShowcases } from "../../services/axios";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation, Pagination]);

export const Hero = () => {
  const [language, setLanguage] = useState("uk");
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [showcase, setShowcase] = useState<Vinyl[] | Cassette[] | []>([]);

  useEffect(() => {
    const fetchShowcases = async () => {
      setShowcase(await getAllShowcases(language));
    };

    fetchShowcases();
  }, [language]);

  const handleBulletClick = (index: number) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index);
    }
  };

  const handleSlideChange = () => {
    if (swiperInstance) {
      setActiveIndex(swiperInstance.realIndex);
    }
  };

  return (
    <section className="hero">
      <div className="hero__thumb">
        <Swiper
          onSwiper={(swiper: any) => setSwiperInstance(swiper)}
          className="hero__swiper"
          spaceBetween={2500}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          speed={750}
          // allowTouchMove={false}
          loop={true}
          slidesPerView={1}
          onSlideChange={handleSlideChange}
        >
          {showcase.map(({ coverPicture, showcaseImage, type }) =>
            type === "vinyl" ? (
              <SwiperSlide>
                <img
                  src={showcaseImage}
                  alt="Альбом"
                  className="hero__image--cassette"
                />
                <img
                  src={coverPicture}
                  alt="Вініл"
                  className="hero__image--wrapper"
                />
              </SwiperSlide>
            ) : (
              <SwiperSlide>
                <img
                  src={showcaseImage}
                  alt="Вініл"
                  className="hero__image--vinyl"
                />
                <img
                  src={coverPicture}
                  alt="Альбом"
                  className="hero__image--album"
                />
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>
      <div className="hero__data">
        <div className="hero__info--wrapper">
          <div className="hero__info">
            <p className="hero__title">{showcase[activeIndex]?.artistName}</p>
            <p className="hero__album">{showcase[activeIndex]?.albumName}</p>
          </div>
          <p className="hero__price">{showcase[activeIndex]?.uah}</p>
        </div>
        <Link to={`/release/${showcase[activeIndex]?._id}`}>
          <button type="button" className="hero__button">
            Придбати
          </button>
        </Link>
        <div className="hero__next--wrapper">
          <p className="hero__next">Далі:</p>
          <p className="hero__next">
            {showcase[activeIndex + 1]?.albumName
              ? showcase[activeIndex + 1]?.albumName
              : showcase[activeIndex - 1]?.albumName}
          </p>
          <p className="hero__next">
            {showcase[activeIndex + 1]?.artistName
              ? showcase[activeIndex + 1]?.artistName
              : showcase[activeIndex - 1]?.artistName}
          </p>
        </div>
        <div className="hero__button--wrapper">
          <button
            type="button"
            ref={prevRef}
            className="hero__swipe--back swiper-button-prev"
          >
            <LeftArrow />
          </button>
          <button
            type="button"
            ref={nextRef}
            className="hero__swipe--next swiper-button-next"
          >
            <RightArrow />
          </button>
        </div>
        <div className="hero__custom--pagination">
          {showcase.map((_, index) => (
            <span
              key={index}
              className={`hero__custom--bullet ${
                index === activeIndex ? "active" : ""
              }`}
              onClick={() => handleBulletClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </section>
  );
};
